import { useGetActiveRaffleQuery } from "../../store/api";
import { ActiveRaffle } from "../home/ActiveRaffle";
import { saveActiveRaffleAction } from "../../store/slices/raffle";
import { NoActiveRaffle } from "../home/NoActiveRaffle";
import { useAppDispatch } from "../../store/hooks/useAppDispatch";
import { RootState } from "../../store/types/rootState";
import { useAppSelector } from "../../store/hooks/useAppSelector";
import { Loading } from "../layout/loading";
import Footer from "../layout/footer";
import FAQDropdown from "../home/FAQDropdown";
import { Box, Typography } from "@mui/material";
import WelcomeBox from "../home/WelcomeBox";


export const Home = () => {
  const dispatch = useAppDispatch();

  const accountId = useAppSelector(
    ({ account }: RootState) => account.id,
  );

  const logo = useAppSelector(
    ({ account }: RootState) => account.logo,
  );

  const { data: activeRaffleFounded, isLoading: isLoadingGetActiveRaffle, error: errorGetActiveFaffle, isUninitialized } = useGetActiveRaffleQuery(accountId, {
    skip: accountId === "default" || accountId === "",
  });
  if (!isLoadingGetActiveRaffle && !errorGetActiveFaffle && !isLoadingGetActiveRaffle && activeRaffleFounded) {
      dispatch(saveActiveRaffleAction(activeRaffleFounded));
  }

  return !isLoadingGetActiveRaffle && !isUninitialized ? (
    <>
      {activeRaffleFounded ? <ActiveRaffle activeRaffle={activeRaffleFounded} /> : <NoActiveRaffle logo={logo} />}
      {accountId !== 'oscar-alarcon' && accountId !== 'minas-de-baruta' && <Footer></Footer>}
    </>
  ) : accountId != 'default' ? <Loading title="Buscando rifas disponibles"></Loading> : <WelcomeBox></WelcomeBox>;
};
