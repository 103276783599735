
import { Box, TextField, MenuItem, FormControl, InputLabel, Select, Typography, SvgIcon, Chip, FormControlLabel, Checkbox, Stack } from "@mui/material";


import NumbersIcon from '@mui/icons-material/Numbers';
import React, { useEffect, useState } from "react";
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import { PAYMENT_METHODS_ICONS, RAFFLE_TYPE_ICONS } from "../../../../constants/icons";
import { PAYMENT_METHODS } from "../../../../constants/paymentMethods";
import { CreateStepperRaffleProps } from "../../../../domain/CreateStepperRaffleProps";
import { ICreationRaffle } from "../../../../store/interfaces/ICreationRaffle";
import { DatePicker, LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dayjs } from "dayjs";
import CountdownTimer from "../../../common/Countdown";


interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(
  function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: Number.parseInt(values.value).toString(),
            },
          });
        }}
        thousandSeparator
        valueIsNumericString
        prefix="$"
      />
    );
  },
);

export const MainFeaturesStep = ({resource, setResource, setDisableNext}: CreateStepperRaffleProps) => {

  const [mainFeaturesResource, setRaffleMainFeaturesResource] = useState<ICreationRaffle>(resource);
  const [hasLauchDate, setHasLauchDate] = useState<boolean>(false);

  useEffect(() => {
    setResource(mainFeaturesResource);
  }, [mainFeaturesResource, setResource])


  const handleChange = (event: any) => {
    let { name, value } = event.target;
    if (name === "limit" && value !== 100) {
      setRaffleMainFeaturesResource((prevState) => ({ ...prevState, type: "" }));
    }

    if (name === "ticketPrice" || name === "ticketPriceUSD" ) {
      setRaffleMainFeaturesResource((prevState) => ({ ...prevState, [name]: Number.parseInt(value) }));
      return
    }
    setRaffleMainFeaturesResource((prevState) => ({ ...prevState, [name]: value }));
  };


useEffect(() => {
  if (setDisableNext !== undefined) {
    setResource(mainFeaturesResource);
    const isFormValid =
      mainFeaturesResource.name.trim() !== "" &&
      mainFeaturesResource.ticketPrice > 0 &&
      mainFeaturesResource.limit > 0 &&
      mainFeaturesResource.type !== "" &&
      mainFeaturesResource.availablePaymentMethods.length > 0;
  
    setDisableNext(!isFormValid);
  }
}, [mainFeaturesResource, setResource, setDisableNext]);
  
const [launchDate, setLaunchDate] = useState<Dayjs | null>(null);
const [launchTime, setLaunchTime] = useState<Dayjs | null>(null);

useEffect(() => {
  if (launchDate && launchTime) {
    const combinedDateTime = launchDate
      .hour(launchTime.hour())
      .minute(launchTime.minute())
      .second(0)
      .add(1, 'day');

    const epochTime = combinedDateTime.unix();

    setRaffleMainFeaturesResource((prevState) => ({ ...prevState, launchDate: epochTime }));
  }
}, [launchDate, launchTime]);

  return (
    <Box p={2}>
      <Box>
        <TextField
          required
          label="Name"
          variant="outlined"
          fullWidth
          name="name"
          value={mainFeaturesResource.name}
          onChange={handleChange}
          inputProps={{ maxLength: 48 }}
        />
      </Box>
      <Box mt={2}>
         <FormControl fullWidth>
          <TextField
            label="Precio del ticket"
            variant="outlined"
            required
            fullWidth
            name="ticketPrice"
            value={mainFeaturesResource.ticketPrice}
            onChange={handleChange}
            id="filled-adornment-amount"
            inputProps={{ maxLength: 7 }}
            InputProps={{
              inputComponent: NumericFormatCustom as any,
            }}
          />
        </FormControl>
      </Box>
      <Box mt={2}>
         <FormControl fullWidth>
          <TextField
            label="Precio del ticket en dólares"
            variant="outlined"
            required
            fullWidth
            name="ticketPriceUSD"
            value={mainFeaturesResource.ticketPriceUSD}
            onChange={handleChange}
            id="filled-adornment-amount"
            inputProps={{ maxLength: 7 }}
            InputProps={{
              inputComponent: NumericFormatCustom as any,
            }}
          />
        </FormControl>
      </Box>
      <Box mt={2}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel>Número de tickets</InputLabel>
          <Select
            required
            label="Número de tickets"
            name="limit"
            value={mainFeaturesResource.limit}
            onChange={handleChange}
            renderValue={(value) => {
              return (
                <Box sx={{ display: "flex", gap: 1 }}>
                  <SvgIcon color="primary">
                    <NumbersIcon />
                  </SvgIcon>
                  {`${value}`}
                </Box>
              );
            }}
          >
            <MenuItem value={100}>100</MenuItem>
            <MenuItem value={1000}>1000</MenuItem>
            <MenuItem value={10000}>10000</MenuItem>
            <MenuItem disabled value={100000}>100000</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box mt={2}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel>Tipo de concurso</InputLabel>
          <Select
            required
            label="Tipo de concurso"
            name="type"
            value={mainFeaturesResource.type}
            onChange={handleChange}
          >
            {RAFFLE_TYPE_ICONS.map((element) => (
              <MenuItem key={element.id} value={element.id}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {element.icon}
                  <Typography ml={1}>{element.name}</Typography>
                </div>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box mt={2}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel>Métodos de pago</InputLabel>
          <Select
            required
            multiple
            name="availablePaymentMethods"
            label="Métodos de pago"
            value={mainFeaturesResource.availablePaymentMethods}
            onChange={handleChange}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip variant="outlined" key={value} label={value}  icon={PAYMENT_METHODS_ICONS("20px").find((icon) => icon.id === value)?.icon} size="medium" />
                ))}
              </Box>
            )}
          >
            {PAYMENT_METHODS.map((element) => (
              <MenuItem key={element.id} value={element.id}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {PAYMENT_METHODS_ICONS("20px").find((icon) => icon.id === element.id)?.icon}
                  <Typography ml={1}>{element.name}</Typography>
                </div>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box mt={2}>
        <FormControlLabel control={<Checkbox
            checked={hasLauchDate}
            onChange={(event) => setHasLauchDate(event.target.checked)}
          />} label="Tiene fecha de lanzamiento" />
      </Box>
      {
        hasLauchDate && <Box mt={2}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack direction={"column"} spacing={4}>
          <DatePicker
            label="Fecha de lanzamiento"
            value={launchDate}
            onChange={(newValue) => setLaunchDate(newValue)}
          />
          <TimePicker
            label="Hora de lanzamiento"
            value={launchTime}
            onChange={(newValue) => setLaunchTime(newValue)}
          />
          {
            hasLauchDate && mainFeaturesResource.launchDate && <CountdownTimer targetEpoch={mainFeaturesResource.launchDate}></CountdownTimer>
          }
          </Stack>
        </LocalizationProvider>
      </Box>
      }
      
    </Box>
  );
};
