import React from "react";
import { Box, Typography, Card, CardContent } from "@mui/material";
import { Email } from "@mui/icons-material";
import { IRaffle } from "../../../store/interfaces/IRaffle";

interface PromoEngineProps {
  raffle: IRaffle;
}

export const PromoEngine = ({ raffle }: PromoEngineProps) => {
  return (
    <Card sx={{ maxWidth: 500, mx: "auto", p: 2, textAlign: "center", borderRadius: 2, boxShadow: 3 }}>
      <CardContent>
        <Typography variant="h5" color="primary" gutterBottom>
          🚀 ¡Novedad en camino!
        </Typography>
        <Typography variant="body1" sx={{ fontSize: "1.1rem" }}>
          Pronto lanzaremos una nueva función: Identificaremos a los 
          <strong> 25 mejores compradores </strong> de una rifa anterior y les enviaremos un 
          <Email sx={{ verticalAlign: "middle", mx: 0.5, color: "green" }} />
          correo promocionando la nueva rifa.
        </Typography>
        <Typography variant="body2" sx={{ color: "gray", mt: 2 }}>
          ¡Más nuevas funcionalidades en camino!
        </Typography>
      </CardContent>
    </Card>
  );
};
