interface Conversion {
    dolarPrice: number;
    dolarPriceCurrency: string;
}

export interface BankAccountItem {
    bankId?: 'santander'
| 'bancofalabella'
| 'bancochile'
| 'bancoestado'
| 'scotiabank'
| 'tenpo'
| 'mercadopago'
| 'paypal'
| 'binance'
| 'plin'
| 'bancolombia'
| 'nequi'
| 'zelle'
| 'yape'
| 'bancodevenezuela'
| 'banesco';
    name: string;
    href?: string;
    dni?: string;
    bank?: string;
    accountType?: string;
    accountNumber?: string;
    ticketPrice?: string;
    ticketPriceAmount: number;
    ticketCurrency: string;
    conversion?: Conversion;
}

interface BankAccountConfig {
    accountId: string;
    bankAccounts: BankAccountItem[]
}

export const BANK_ACCOUNTS: BankAccountConfig[] = [
    {
        accountId: "oscar-alarcon",
        bankAccounts: [
            {
                bankId: 'tenpo',
                name: "Diana Medina",
                dni: "Rut: 19879108-3",
                bank: "Banco Prepago Tenpo 🇨🇱",
                accountType: "Cuenta Vista",
                accountNumber: "111119879108",
                ticketPrice: "3000 pesos",
                ticketPriceAmount: 3000,
                href: 'https://yourbankwebsite-chile.com',
                ticketCurrency: "CLP"
            },
            {
                bankId: 'mercadopago',
                name: "Oscar Donato Alarcon",
                dni: "Rut: 27.591.242-5",
                bank: "Mercado Pago 📲",
                accountType: "Cuenta Vista",
                accountNumber: "1030647762",
                ticketPrice: "3000 pesos",
                ticketPriceAmount: 3000,
                href: 'https://yourbankwebsite-chile.com',
                ticketCurrency: "CLP"
            },
            {
                bankId: 'bancoestado',
                name: "Oscar Alarcon",
                dni: "Rut: 27.591.242-5",
                bank: "Banco Estado 🇨🇱",
                accountType: "Cuenta Corriente",
                accountNumber: "00100416484",
                ticketPrice: "3000 pesos",
                ticketPriceAmount: 3000,
                href: 'https://yourbankwebsite-chile.com',
                ticketCurrency: "CLP"
            },
            {
                bankId: 'bancoestado',
                name: "Oskarli Alarcon",
                dni: "Rut: 27.848.017-8",
                bank: "Banco Estado 🇨🇱",
                accountType: "Cuenta RUT",
                accountNumber: "27848017",
                ticketPrice: "3000 pesos",
                ticketPriceAmount: 3000,
                href: 'https://yourbankwebsite-chile.com',
                ticketCurrency: "CLP"
            },
            {
                bankId: 'paypal',
                bank: "Paypal 🇺🇸 ",   
                name: "oscaralarcon048@gmail.com",
                accountNumber: "941714665",
                ticketPrice: "$3 dólar",
                ticketPriceAmount: 3,
                ticketCurrency: "USD"
            },
            {
                bankId: 'binance',
                bank: "Binance 🇻🇪 🇺🇸 ",   
                name: "Oscar Alarcon",
                accountNumber: "941714665",
                ticketPrice: "$3 dólar",
                ticketPriceAmount: 3,
                ticketCurrency: "USD"
            },
            {
                bankId: 'plin',
                name: 'Plin',
                bank: "Perú 🇵🇪",
                dni: "Plin: 928821360",
                accountNumber: '928821360',
                ticketPrice: "$13 soles",
                ticketPriceAmount: 13,
                ticketCurrency: "SOL"
            },
            {
                bankId: 'bancolombia',
                name: "Jerson Andrés Alzate Cifuentes",
                dni: "Cédula: 1.0007.877.596",
                bank: "Bancolombia 🇨🇴",
                accountType: "Cuenta de ahorro",
                accountNumber: "912-268373-08",
                ticketPrice: "12400 pesos",
                ticketPriceAmount: 12400,
                href: 'https://yourbankwebsite-chile.com',
                ticketCurrency: "COP"
            },
            {
                bankId: 'bancodevenezuela',
                name: "Jiselh Andreina Rojas Ariza",
                dni: "Cédula: V-28.24.74.25",
                bank: "Banco de Venezuela 🇻🇪 - 01020180220000286015",
                accountNumber: "+58 0412-5183750",
                accountType: "VES",
                ticketPrice: "315bs",
                ticketPriceAmount: 315,
                href: 'https://yourbankwebsite-chile.com',
                ticketCurrency: "BS",
                conversion: {
                    dolarPrice: 105,
                    dolarPriceCurrency: "BS",
                }
            },
        ]
    },
    {
        accountId: "goyo2time",
        bankAccounts: [
            {
                name: "Jose Luque",
                dni: "Rut: 26.525.588-4",
                bank: "Banco de Chile 🇨🇱",
                accountType: "Cuenta Vista",
                accountNumber: "00-015-57222-15",
                ticketPrice: "2000 pesos",
                ticketPriceAmount: 2000,
                href: 'https://yourbankwebsite-chile.com',
                ticketCurrency: "CLP"
            },
            {
                name: "Yence Macero",
                dni: "Cédula: V-6.825.769",
                bank: "Banesco - Pago móvil 🇻🇪",
                accountNumber: "0134 0215 9421 5105 4891",
                accountType: "Corriente",
                ticketPrice: "86Bs",
                ticketPriceAmount: 86,
                href: 'https://yourbankwebsite-chile.com',
                ticketCurrency: "BS",
                conversion: {
                    dolarPrice: 47.55,
                    dolarPriceCurrency: "BS",
                }
            },
            {
                name: "Jose Luque",
                dni: "RUT: 26.525.588-4",
                bank: "Banco Estado🇨🇱",
                accountNumber: "26525588",
                accountType: "Cuenta RUT",
                ticketPrice: "2000 CLP",
                ticketPriceAmount: 2000,
                href: 'https://yourbankwebsite-chile.com',
                ticketCurrency: "CLP",
            },
            {
                name: "Jose Luque",
                dni: "RUT: 26.525.588-4",
                bank: "Banco BCI🇨🇱",
                accountNumber: "777926525588",
                accountType: "Cuenta Corriente",
                ticketPrice: "2000 CLP",
                ticketPriceAmount: 2000,
                href: 'https://yourbankwebsite-chile.com',
                ticketCurrency: "CLP",
            },
            {
                name: "Michael Dominguez",
                bank: "Zelle 🇻🇪 🇺🇸",
                accountNumber: "3214404791",
                dni: "piruetasmk@gmail.com",
                ticketPrice: "$2 dólar",
                ticketPriceAmount: 2,
                ticketCurrency: "USD"
            },
        ]
    },
    {
        accountId: "maikel-dominguez",
        bankAccounts: [
            {
                name: "Oscar Alarcon",
                dni: "Rut: 27.591.242-5",
                bank: "Mercado Pago 🇨🇱",
                accountType: "Cuenta Vista",
                accountNumber: "1030647762",
                ticketPrice: "1000 pesos",
                ticketPriceAmount: 1000,
                href: 'https://yourbankwebsite-chile.com',
                ticketCurrency: "CLP"
            },
            {
                name: "Michael Dominguez",
                bank: "Zelle 🇻🇪 🇺🇸",
                accountNumber: "3214404791",
                dni: "michaeliriarte315@gmail.com",
                ticketPrice: "$1 dólar",
                ticketPriceAmount: 1,
                ticketCurrency: "USD"
            },
            {
                name: 'Pago móvil',
                dni: "Cédula: V-25.327.420",
                bank: "Banco de Venezuela - Pago móvil 🇻🇪",
                accountNumber: "04123868075",
                ticketPrice: "99Bs",
                ticketPriceAmount: 99,
                href: 'https://yourbankwebsite-chile.com',
                ticketCurrency: "BS",
                conversion: {
                    dolarPrice: 99, 
                    dolarPriceCurrency: "BS",
                }
            },
            {
                name: 'Paypal',
                bank: "Paypal",
                accountNumber: "piruetasmk@gmail.com",
                dni: "piruetasmk@gmail.com",
                ticketPrice: "$1 dólar",
                ticketPriceAmount: 1,
                ticketCurrency: "USD"
            },
            {
                name: "Franklin Mera Rios",
                dni: "Cédula: 0962434452",
                bank: "Ecuador 🇪🇨",
                accountType: "Cuenta Corriente",
                accountNumber: "28255866",
                ticketPrice: "1 USD",
                ticketPriceAmount: 1,
                href: 'https://yourbankwebsite-chile.com',
                ticketCurrency: "USD"
            },
            
        ]
    },  
    {
        accountId: 'rifa-sport',
        bankAccounts: [
            {
                bankId: 'tenpo',
                name: "Diana Medina",
                dni: "Rut: 19879108-3",
                bank: "Banco Prepago Tenpo 🇨🇱",
                accountType: "Cuenta Vista",
                accountNumber: "111119879108",
                ticketPrice: "1500 pesos",
                ticketPriceAmount: 1500,
                href: 'https://yourbankwebsite-chile.com',
                ticketCurrency: "CLP"
            },
            {
                bankId: 'mercadopago',
                name: "Oscar Donato Alarcon",
                dni: "Rut: 27.591.242-5",
                bank: "Mercado Pago 📲",
                accountType: "Cuenta Vista",
                accountNumber: "1030647762",
                ticketPrice: "1500 pesos",
                ticketPriceAmount: 1500,
                href: 'https://yourbankwebsite-chile.com',
                ticketCurrency: "CLP"
            },
            {
                bankId: 'bancoestado',
                name: "Oscar Alarcon",
                dni: "Rut: 27.591.242-5",
                bank: "Banco Estado 🇨🇱",
                accountType: "Cuenta Corriente",
                accountNumber: "00100416484",
                ticketPrice: "1500 pesos",
                ticketPriceAmount: 1500,
                href: 'https://yourbankwebsite-chile.com',
                ticketCurrency: "CLP"
            },
            {
                bankId: 'bancoestado',
                name: "Oskarli Alarcon",
                dni: "Rut: 27.848.017-8",
                bank: "Banco Estado 🇨🇱",
                accountType: "Cuenta RUT",
                accountNumber: "27848017",
                ticketPrice: "1500 pesos",
                ticketPriceAmount: 1500,
                href: 'https://yourbankwebsite-chile.com',
                ticketCurrency: "CLP"
            },
            {
                bankId: 'paypal',
                bank: "Paypal 🇺🇸 ",   
                name: "oscaralarcon048@gmail.com",
                accountNumber: "941714665",
                ticketPrice: "$1.5 dólar",
                ticketPriceAmount: 1.5,
                ticketCurrency: "USD"
            },
            {
                bankId: 'binance',
                bank: "Binance 🇻🇪 🇺🇸 ",   
                name: "Oscar Alarcon",
                accountNumber: "941714665",
                ticketPrice: "$1.5 dólar",
                ticketPriceAmount: 1.5,
                ticketCurrency: "USD"
            },
            {
                bankId: 'bancolombia',
                name: "Jerson Andrés Alzate Cifuentes",
                dni: "Cédula: 1.0007.877.596",
                bank: "Bancolombia 🇨🇴",
                accountType: "Cuenta de ahorro",
                accountNumber: "912-268373-08",
                ticketPrice: "6200 pesos",
                ticketPriceAmount: 6200,
                href: 'https://yourbankwebsite-chile.com',
                ticketCurrency: "COP"
            },
            {
                bankId: 'bancodevenezuela',
                name: "Jiselh Andreina Rojas Ariza",
                dni: "Cédula: V-28.24.74.25",
                bank: "Banco de Venezuela 🇻🇪 - 01020180220000286015",
                accountNumber: "+58 0412-5183750",
                accountType: "VES",
                ticketPrice: "120bs",
                ticketPriceAmount: 120,
                href: 'https://yourbankwebsite-chile.com',
                ticketCurrency: "BS",
                conversion: {
                    dolarPrice: 80,
                    dolarPriceCurrency: "BS",
                }
            },
        ]
    },
    {
        accountId: 'w-robles',
        bankAccounts: [
            {
                name: 'William Robles',
                dni: "Cédula: V-123123123",
                bank: "Banco de Venezuela 🇻🇪",
                accountNumber: "0424123123123",
                accountType: "Pago Móvil",
                ticketPrice: "86Bs",
                ticketPriceAmount: 86,
                href: 'https://yourbankwebsite-chile.com',
                ticketCurrency: "BS",
                conversion: {
                    dolarPrice: 86, 
                    dolarPriceCurrency: "BS",
                }
            },
        ]
    },
    {
        accountId: 'rifas-cachorro',
        bankAccounts: [
            {
                name: "Alva Rodriguez",
                bank: "Zelle 🇺🇸",
                dni: "alvalorena843@gmail.com",
                ticketPrice: "$0.5 dólar",
                ticketPriceAmount: 0.5,
                ticketCurrency: "USD"
            },
            {
                name: "Alejandra Sosa",
                bank: "Bancolombia - Nequi 🇨🇴",
                accountType: "Nequi",
                ticketPrice: "2500 pesos",
                ticketPriceAmount: 2500,
                href: 'https://yourbankwebsite-chile.com',
                ticketCurrency: "COP"
            },
            {
                dni: "Cédula: V-19.672.105",
                bank: "Banco de Venezuela 🇻🇪",
                accountNumber: "04260368746",
                name: "Pago Móvil",
                ticketPrice: "40Bs",
                ticketPriceAmount: 40,
                href: 'https://yourbankwebsite-chile.com',
                ticketCurrency: "BS",
                conversion: {
                    dolarPrice: 86, 
                    dolarPriceCurrency: "BS",
                }
            },
        ]
    },
    {
        accountId: 'rifas-oriente',
        bankAccounts: [
            {
                bank: "Zelle 🇺🇸",
                dni: "9515058025",
                name: 'Anderson Colon',
                ticketPrice: "$3 dólar",
                ticketPriceAmount: 3,
                ticketCurrency: "USD"
            },
            {
                name: "Luis Carlos Lopez Escalona",
                dni: "Rut: 26158783-1",
                bank: "Banco Estado 🇨🇱",
                accountType: "Cuenta RUT",
                accountNumber: "26158783",
                ticketPrice: "3000 pesos",
                ticketPriceAmount: 3000,
                href: 'https://yourbankwebsite-chile.com',
                ticketCurrency: "CLP"
            },     
            {
                name: 'Banesco',
                dni: "Cédula: V-24.513.303",
                bank: "Banesco - 0134 🇻🇪 Pago Móvil",
                accountNumber: '04148439871',
                ticketPrice: "240Bs",
                ticketPriceAmount: 240,
                href: 'https://yourbankwebsite-chile.com',
                ticketCurrency: "BS",
                conversion: {
                    dolarPrice: 86, 
                    dolarPriceCurrency: "BS",
                }
            },
        ]
    },
    {
        accountId: 'luis-bigrau',
        bankAccounts: [
            {
                name: 'Banco Mercantil',
                dni: "Cédula: V-20.489.389",
                bank: "Banco Mercantil 🇻🇪",
                accountNumber: "04261871035",
                accountType: "Pago Móvil",
                ticketPrice: "40Bs",
                ticketPriceAmount: 40,
                href: 'https://yourbankwebsite-chile.com',
                ticketCurrency: "BS",
                conversion: {
                    dolarPrice: 86, 
                    dolarPriceCurrency: "BS",
                }
            },
            {
                name: "Luis Delgado",
                bank: "Zelle 🇺🇸",
                dni: "Ld864808@gmail.com",
                ticketPrice: "$0.5 dólar",
                ticketPriceAmount: 0.5,
                ticketCurrency: "USD"
            },
            {
                bank: "Bizum 🇪🇸",
                name: 'Jhondaiver Daniel Alvarez Ponte',
                accountNumber: '613368445',
                ticketPrice: "$0.5 euro",
                ticketPriceAmount: 0.5,
                ticketCurrency: "USD"
            },
        ]
    },
    {
        accountId: 'ruedas-extremas',
        bankAccounts: [
            {
                bankId: 'bancodevenezuela',
                name: 'Banco De Venezuela',
                dni: "Cédula: V-17.613.821",
                bank: "Banco de Venezuela 🇻🇪",
                accountNumber: "04144074382",
                accountType: "Pago Móvil",
                ticketPrice: "101Bs",
                ticketPriceAmount: 101,
                href: 'https://yourbankwebsite-chile.com',
                ticketCurrency: "BS",
                conversion: {
                    dolarPrice: 101, 
                    dolarPriceCurrency: "BS",
                }
            },
            {
                bankId: 'zelle',
                name: "Fabian Valdespino",
                bank: "Zelle 🇺🇸",
                dni: "fabianvaldespino7@gmail.com",
                ticketPrice: "$1 dólar",
                ticketPriceAmount: 1,
                ticketCurrency: "USD"
            },
            {
                bankId: 'bancofalabella',
                name: "Yinderson Gonzalez",
                dni: "Rut: 27.576.928-2",
                bank: "Banco Falabella 🇨🇱",
                accountType: "Cuenta Corriente",
                accountNumber: "1-519-011940-7",
                ticketPrice: "1000 pesos",
                ticketPriceAmount: 1000,
                href: 'https://yourbankwebsite-chile.com',
                ticketCurrency: "CLP"
            },
        ]
    },
    // {
    //     accountId: 'yoiber-mota',
    //     bankAccounts: [
    //         {
    //             dni: "Cédula: V-30.773.196",
    //             bank: "Banco de Venezuela 🇻🇪",
    //             accountNumber: "04129356630",
    //             accountType: "Pago Móvil",
    //             ticketPrice: "86Bs",
    //             ticketPriceAmount: 86,
    //             href: 'https://yourbankwebsite-chile.com',
    //             ticketCurrency: "BS",
    //             conversion: {
    //                 dolarPrice: 86, 
    //                 dolarPriceCurrency: "BS",
    //             }
    //         },
    //     ]
    // },
    {
        accountId: 'zpremio',
        bankAccounts: [
            {
                name: 'Banesco',
                bankId: 'banesco',
                dni: "Cédula: V-25.841.797",
                bank: "Banesco 🇻🇪",
                accountNumber: "04144763877",
                accountType: "Pago Móvil",
                ticketPrice: "306Bs",
                ticketPriceAmount: 306,
                href: 'https://yourbankwebsite-chile.com',
                ticketCurrency: "BS",
                conversion: {
                    dolarPrice: 101, 
                    dolarPriceCurrency: "BS",
                }
            },
            {
                bankId: 'nequi',
                bank: "Nequi 🇨🇴",
                name: "Yuri Andrea Pacheco Arroyo",
                accountNumber: "3157067137",
                ticketPrice: "$12000 COP",
                ticketPriceAmount: 12000,
                ticketCurrency: "USD"
            },
            {
                bankId: 'zelle',
                bank: "Zelle 🇺🇸 (compra minima 10 tickets)",
                name: 'Kevin Rodríguez',
                accountNumber: "kevinsio2013@gmail.com",
                ticketPrice: "$3 dólar",
                ticketPriceAmount: 3,
                ticketCurrency: "USD"
            },
            {
                bankId: 'mercadopago',
                name: "Kelvinsito",
                bank: "Mercado Pago 🇦🇷",
                accountNumber: "CVU: 0000003100057960698723",
                ticketPrice: "9600 pesos",
                ticketPriceAmount: 9600,
                href: 'https://yourbankwebsite-chile.com',
                ticketCurrency: "ARG"
            },
            {
                bankId: 'bancoestado',
                name: "Ana Mary Ascanio Bermejo",
                dni: "Rut: 28170238-6",
                bank: "Banco Estado 📲",
                accountType: "Cuenta RUT",
                accountNumber: "28170238",
                ticketPrice: "3000 pesos",
                ticketPriceAmount: 3000,
                href: 'https://yourbankwebsite-chile.com',
                ticketCurrency: "CLP"
            },
        ]
    },
    {
        accountId: 'motor-luck',
        bankAccounts: [
            {
                bankId: 'tenpo',
                name: "Clever Fernandez",
                dni: "Rut: 26962052-8",
                bank: "Banco Prepago Tenpo 🇨🇱",
                accountType: "Cuenta Vista",
                accountNumber: "111126962052",
                ticketPrice: "1200 pesos",
                ticketPriceAmount: 1200,
                href: 'https://yourbankwebsite-chile.com',
                ticketCurrency: "CLP"
            },
            {
                name: 'Banco de Venezuela',
                bankId: 'bancodevenezuela',
                dni: "Cédula: V-18.564.518",
                bank: "Banco de Venezuela 🇻🇪",
                accountNumber: '04147060268',
                accountType: 'Pago móvil',
                ticketPrice: "110Bs",
                ticketPriceAmount: 110,
                href: 'https://yourbankwebsite-chile.com',
                ticketCurrency: "BS",
                conversion: {
                    dolarPrice: 89, 
                    dolarPriceCurrency: "BS",
                }
            },
            {
                name: 'Binance',
                bankId: 'binance',
                bank: "Binance 🇺🇸 ",   
                accountNumber: "393620915",
                ticketPrice: "$1.2 dólar",
                ticketPriceAmount: 1.2,
                ticketCurrency: "USD"
            },
            {
                bankId: 'scotiabank',
                bank: "BBVA - España 🇪🇸",   
                accountNumber: "ES82 0182 5322 2402 0374 9203",
                name: 'Aleanger Vielma',
                accountType: 'Corriente',
                ticketPrice: "$1.1 euro",
                ticketPriceAmount: 1.1,
                ticketCurrency: "USD"
            },
            {
                bankId: 'bancolombia',
                name: "Anderson Tobón Cardona",
                dni: "Cédula: 1.0007.877.596",
                bank: "Bancolombia 🇨🇴",
                accountType: "Cuenta de ahorro",
                accountNumber: "816-515565-11",
                ticketPrice: "5400 pesos",
                ticketPriceAmount: 5400,
                href: 'https://yourbankwebsite-chile.com',
                ticketCurrency: "COP"
            },
            {
                bankId: 'bancolombia',
                name: "Anderson Tobón Cardona",
                dni: "Cédula: 1.0007.877.596",
                bank: "Bancolombia 🇨🇴",
                accountType: "Cuenta de ahorro",
                accountNumber: "816-515565-11",
                ticketPrice: "5400 pesos",
                ticketPriceAmount: 5400,
                href: 'https://yourbankwebsite-chile.com',
                ticketCurrency: "COP"
            },
        ]
    },
    {
        accountId: 'rifas-naguara',
        bankAccounts: [
                {
                    bankId: 'bancodevenezuela',
                    name: 'Banco de Venezuela',
                    dni: "Cédula: V-20.925.858",
                    bank: "Banco de Venezuela 🇻🇪 Pago Móvil",
                    accountNumber: '04245568388',
                    ticketPrice: "515.75Bs",
                    ticketPriceAmount: 515.75,
                    href: 'https://yourbankwebsite-chile.com',
                    ticketCurrency: "BS",
                    conversion: {
                        dolarPrice: 103.15, 
                        dolarPriceCurrency: "BS",
                    }
                },
                {
                    name: 'Paypal',
                    bankId: 'paypal',
                    bank: "Paypal",
                    dni: "jesusanthinai@gmail.com",
                    accountNumber: "333460265817",
                    ticketPrice: "$5 dólar",
                    ticketPriceAmount: 5,
                    ticketCurrency: "USD"
                },
                {
                    bankId: 'bancoestado',
                    name: "Xavier Martinez",
                    dni: "Rut: 27.078.811-4",
                    bank: "Banco Estado 📲",
                    accountType: "Cuenta RUT",
                    accountNumber: "00027078811",
                    ticketPrice: "5000 pesos",
                    ticketPriceAmount: 5000,
                    href: 'https://yourbankwebsite-chile.com',
                    ticketCurrency: "CLP"
                },
                {
                    name: 'Nequi',
                    bankId: 'nequi',
                    bank: "Nequi",
                    accountNumber: "3226937886",
                    ticketPrice: "$20000 COP",
                    ticketPriceAmount: 20000,
                    ticketCurrency: "USD"
                },
                {
                    bankId: 'zelle',
                    bank: "Zelle 🇺🇸",
                    name: 'Jesus Rodriguez',
                    accountNumber: "jesusanthinai@gmail.com",
                    ticketPrice: "$5 dólar",
                    ticketPriceAmount: 5,
                    ticketCurrency: "USD"
                },
                {
                    bankId: 'yape',
                    name: 'Yape',
                    bank: 'Yape',
                    accountNumber: "+51 993 349 274",
                    ticketPrice: "$20 soles",
                    ticketPriceAmount: 20,
                    ticketCurrency: "USD"
                }
        ]
    },
    {
        accountId: 'antoni-contreras',
        bankAccounts: [
                {
                    name: 'Antoni Contreras',
                    dni: "Cédula: V-31.243.539",
                    bank: "BFC - 0151 🇻🇪 Pago Móvil",
                    accountNumber: '04248404632',
                    ticketPrice: "102Bs",
                    ticketPriceAmount: 102,
                    href: 'https://yourbankwebsite-chile.com',
                    ticketCurrency: "BS",
                    conversion: {
                        dolarPrice: 102, 
                        dolarPriceCurrency: "BS",
                    }
                },
                {
                    bankId: 'bancolombia',
                    name: "Lismar Mendez",
                    bank: "Bancolombia 🇨🇴",
                    accountType: "Cuenta de ahorro",
                    accountNumber: "82400009131",
                    ticketPrice: "4000 pesos",
                    ticketPriceAmount: 4000,
                    href: 'https://yourbankwebsite-chile.com',
                    ticketCurrency: "COP"
                },
                {
                    bank: "Zelle 🇺🇸",
                    name: 'Emely Baca',
                    dni: "6309974211",
                    ticketPrice: "$1 dólar",
                    ticketPriceAmount: 1,
                    ticketCurrency: "USD"
                },
                {
                    name: 'Antoni Contreras',
                    bank: "Binance",
                    accountNumber: "751618875",
                    ticketPrice: "$1 dólar",
                    ticketPriceAmount: 1,
                    ticketCurrency: "USD"
                },
        ]
    },
    {
        accountId: 'el-barines',
        bankAccounts: [
                {
                    name: 'Rifas El Barines',
                    dni: "Cédula: V-25.399.716",
                    bank: "Banco de Venezuela 🇻🇪 Pago Móvil",
                    accountNumber: '04249312121',
                    ticketPrice: "198Bs",
                    ticketPriceAmount: 198,
                    href: 'https://yourbankwebsite-chile.com',
                    ticketCurrency: "BS",
                    conversion: {
                        dolarPrice: 99, 
                        dolarPriceCurrency: "BS",
                    }
                },
                {
                    name: "Nelson Jose Angulo Rojo",
                    dni: "Rut: 25.982.697-7",
                    bank: "Mercado Pago 📲",
                    accountType: "Cuenta Vista",
                    accountNumber: "1035819395",
                    ticketPrice: "2000 pesos",
                    ticketPriceAmount: 2000,
                    href: 'https://yourbankwebsite-chile.com',
                    ticketCurrency: "CLP"
                },
                {
                    name: 'Rifas El Barines',
                    bank: "Binance",
                    accountNumber: "501399085",
                    ticketPrice: "$2 dólar",
                    ticketPriceAmount: 2,
                    ticketCurrency: "USD"
                },
        ]
    },
    {
        accountId: 'white-lion',
        bankAccounts: [
            {
                name: 'Cuenta Zelle',
                bankId: 'zelle',
                bank: "Zelle 🇺🇸",
                accountNumber: "avyoha91@gmail.com",
                ticketPrice: "$12 dólar",
                ticketPriceAmount: 12,
                ticketCurrency: "USD"
            },
        ]
    },
    {
        accountId: 'rifas-roalis',
        bankAccounts: [
            { 
                name: 'Cuenta BDV',
                bankId: 'bancodevenezuela',
                dni: "Cédula: V-19.523.141",
                bank: "Banco De Venezuela - Pago móvil",
                accountNumber: "04121314692",
                ticketPrice: "3 dólares ó 318Bs",
                ticketPriceAmount: 318,
                href: 'https://yourbankwebsite-chile.com',
                ticketCurrency: "BS",
                conversion: {
                    dolarPrice: 106,
                    dolarPriceCurrency: "BS",
                }
            }, 
            {
                name: 'Cuenta Banesco',
                bankId: 'banesco',
                dni: "Cédula: V-17.717.698",
                bank: "Banesco - Pago móvil 🇻🇪",
                accountNumber: "04243162922",
                accountType: "Pago móvil",
                ticketPrice: "3 dólares ó 318Bs",
                ticketPriceAmount: 318,
                href: 'https://yourbankwebsite-chile.com',
                ticketCurrency: "BS",
                conversion: {
                    dolarPrice: 106,
                    dolarPriceCurrency: "BS",
                }
            },
            {
                name: 'Cuenta Binance',
                bankId: 'binance',
                bank: "Binance",
                accountNumber: "520623253",
                ticketPrice: "$3 dólar",
                ticketPriceAmount: 3,
                ticketCurrency: "USD"
            },
        ]
    },
    {
        accountId: 'minas-de-baruta',
        bankAccounts: [
            {
                name: 'Cuena Banco Mercantil',
                dni: "Cédula: V-18.714.122",
                bank: "Banco Mercantil - Pago móvil 🇻🇪",
                accountNumber: "04120218723",
                ticketPrice: "1 dólar ó 99Bs",
                ticketPriceAmount: 99,
                href: 'https://yourbankwebsite-chile.com',
                ticketCurrency: "BS",
                conversion: {
                    dolarPrice: 99,
                    dolarPriceCurrency: "BS",
                }
            },
            {
                bank: "Zelle 🇺🇸",
                name: 'Carlos Cruzco',
                dni: "Carloscruzco23@gmail.com",
                ticketPrice: "$1 dólar",
                ticketPriceAmount: 1,
                ticketCurrency: "USD"
            },
            {
                bank: "Banco: Guayaquil 🇪🇨",
                accountType: 'Cta de Ahorro',
                accountNumber: "#0028255866",
                name: 'Franklin Mera',
                ticketPrice: "1 dólar",
                ticketPriceAmount: 1,
                href: 'https://yourbankwebsite-chile.com',
                ticketCurrency: "USD",
            },
            {
                bank: "Banco: BCI MACH 🇨🇱",
                accountType: 'Cta Corriente',
                dni: '27953221-K',
                accountNumber: "777927953221",
                name: 'Martha Michelle',
                ticketPrice: "1 dólar",
                ticketPriceAmount: 1,
                href: 'https://yourbankwebsite-chile.com',
                ticketCurrency: "USD",
            },
        ]
    },
    {
        accountId: 'vip-rifas',
        bankAccounts: [
            {
                name: 'Vip Rifas',
                dni: "Cédula: V-26.901.703",
                bank: "Banco Venezuela - Pago móvil 🇻🇪",
                accountNumber: "04241982696",
                ticketPrice: "3 dólar ó 121,77Bs",
                ticketPriceAmount: 121.77,
                href: 'https://yourbankwebsite-chile.com',
                ticketCurrency: "BS",
            },
        ]
    },
    {
        accountId: 'danielcontrerasrifas',
        bankAccounts: [
            {
                name: "Juan Carlos Perez Rodriguez",
                dni: "Rut: 26.669.894-1",
                bank: "Banco Falabella 🇨🇱",
                accountType: "Cuenta Corriente",
                accountNumber: "1-253-002108-2",
                ticketPrice: "500 pesos",
                ticketPriceAmount: 500,
                href: 'https://yourbankwebsite-chile.com',
                ticketCurrency: "CLP"
            },
            {
                bank: "Zelle 🇻🇪 🇺🇸",
                name: "Fausto Contreras",
                dni: "danielcontrerasrifas@gmail.com",
                ticketPrice: "$0.5 dólar",
                ticketPriceAmount: 0.5,
                ticketCurrency: "USD"
            },
            {
                dni: "Cédula: V-26.807.287",
                name: "Martha Cecilia Perez Rodriguez",
                bank: "Banco Venezuela - Pago móvil 🇻🇪",
                accountNumber: "04168782745",
                ticketPrice: "0.5 dólar ó 23.8Bs",
                ticketPriceAmount: 23.8,
                href: 'https://yourbankwebsite-chile.com',
                ticketCurrency: "BS",
                conversion: {
                    dolarPrice: 47.55,
                    dolarPriceCurrency: "BS",
                }
            },
            // {
            //     bank: "Paypal",
            //     accountNumber: "danielcontrerasrifas@gmail.com",
            //     dni: "danielcontrerasrifas@gmail.com",
            //     ticketPrice: "$0.5 dólar",
            //     ticketPriceAmount: 0.5,
            //     ticketCurrency: "USD"
            // },
        ]
    },
    // {
    //     accountId: "galenstar",
    //     bankAccounts: [
    //         {
    //             dni: "Cédula: V-28.416.990",
    //             bank: "Banco Venezuela - Pago móvil 🇻🇪",
    //             accountNumber: "04127746302",
    //             ticketPrice: "0.5 dólar ó 34.9Bs",
    //             ticketPriceAmount: 34.9,
    //             href: 'https://yourbankwebsite-chile.com',
    //             ticketCurrency: "BS",
    //             conversion: {
    //                 dolarPrice: 69.80,
    //                 dolarPriceCurrency: "BS",
    //             }
    //         },
    //         {
    //             bank: "Binance 🇻🇪 🇺🇸",
    //             accountNumber: "837671980",
    //             ticketPrice: "$0.5 dólar",
    //             ticketPriceAmount: 0.5,
    //             ticketCurrency: "USD"
    //         },
    //         {
    //             bank: "Zelle 🇻🇪 🇺🇸",
    //             dni: "venezuela2022victoria@gmail.com",
    //             ticketPrice: "$0.5 dólar",
    //             ticketPriceAmount: 0.5,
    //             ticketCurrency: "USD"
    //         }
    //     ]
    // },
    {
        accountId: "maikel-jaimez",
        bankAccounts: [
            // {
            //     dni: "Cédula: V-21.376.723",
            //     bank: "Banco Mercantil - Pago móvil 🇻🇪",
            //     accountNumber: "04242228877",
            //     ticketPrice: "1 dólar ó 40Bs",
            //     ticketPriceAmount: 40,
            //     href: 'https://yourbankwebsite-chile.com',
            //     ticketCurrency: "BS",
            //     conversion: {
            //         dolarPrice: 47.55,
            //         dolarPriceCurrency: "BS",
            //     }
            // },
            { 
                name: 'Maikel Jaimez',
                dni: "Cédula: V-11.314.875",
                bank: "Banco De Venezuela - Pago móvil 🇻🇪",
                accountNumber: "04141547202",
                ticketPrice: "1 dólar ó 60Bs",
                ticketPriceAmount: 60,
                href: 'https://yourbankwebsite-chile.com',
                ticketCurrency: "BS",
                conversion: {
                    dolarPrice: 60,
                    dolarPriceCurrency: "BS",
                }
            }, 
            {
                dni: "RUT: 28.113.514-7",
                name: "Jose Martinez",
                accountType: "Cuenta Corriente",
                bank: "BCI/MACH 🇨🇱",
                accountNumber: "777928113514",
                ticketPrice: "1000 pesos",
                ticketPriceAmount: 1000,
                href: 'https://yourbankwebsite-chile.com',
                ticketCurrency: "CLP",
            },
            {
                name: "Gerson Morales",
                bank: "Zelle 🇺🇸",
                dni: "elbarbergrau175@gmail.com",
                ticketPrice: "$1 dólar",
                ticketPriceAmount: 1,
                ticketCurrency: "USD"
            },
            {
                name: "Maikel Jaimez",
                bank: "Yape Perú 🇵🇪",
                dni: "+51 921 565 925",
                ticketPrice: "4 soles",
                ticketPriceAmount: 4,
                ticketCurrency: "SOL"
            }
        ]
    },
    {
        accountId: "blessed-disc-bar-zz",
        bankAccounts: [
            {
                name: "Pablo Emiliano Díaz Gutiérrez",
                bank: "Bancolombia 🇨🇴",
                dni: "Cédula: 1046743290",
                accountType: "Cuenta de ahorro",
                accountNumber: "31156081330",
                ticketPrice: "2000 pesos",
                ticketPriceAmount: 2000,
                href: 'https://yourbankwebsite-chile.com',
                ticketCurrency: "COP"
            },
            {
                name: "Erlin Orozco",
                bank: "Zelle 🇻🇪 🇺🇸",
                accountNumber: "+1 (929) 317-7257",
                ticketPrice: "$0.5 dólar",
                ticketPriceAmount: 0.5,
                ticketCurrency: "USD"
            },
            {
                name: "Keiver José Pacheco Pernalete",
                dni: "Cédula: V303334670",
                bank: "Banco de Venezuela 🇻🇪",
                accountType: "Cuenta de ahorro",
                accountNumber: "01020327910000432380",
                ticketPrice: "20 bs",
                ticketPriceAmount: 20,
                href: 'https://yourbankwebsite-chile.com',
                ticketCurrency: "BS"
            },
            {
                name: "0412-8670001",
                dni: "Cédula: V30334670",
                bank: "Banco de Venezuela - Pago móvil 🇻🇪",
                accountType: "Corriente",
                accountNumber: "0412-8670001",
                ticketPrice: "20Bs",
                ticketPriceAmount: 20,
                href: 'https://yourbankwebsite-chile.com',
                ticketCurrency: "BS"
            },
            // {
            //     bank: "Binance",
            //     accountNumber: "837671980",
            //     dni: "leonel.roca1@icloud.com",
            //     ticketPrice: "$0.5 dólar",
            //     ticketPriceAmount: 0.5,
            //     ticketCurrency: "USD"
            // },
        ]
    },
]
