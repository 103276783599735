import React, { useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  Paper,
  Grid,
  Pagination,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  IconButton,
  ListItem,
  Button,
  OutlinedInput,
  InputAdornment,
  CircularProgress,
  DialogActions,
} from '@mui/material';
import { IPurchaseOrder } from '../../store/interfaces/IPurchaseOrder';
import { PAYMENT_METHODS_ICONS } from '../../constants/icons';
import { PURCHASE_ORDER_STATUS } from '../../constants/purchaseOrderStatus';
import dayjs from 'dayjs';
import CloseIcon from '@mui/icons-material/Close';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Close, DeleteForever, Visibility, VisibilityOff } from '@mui/icons-material';
import { useIsMobileView } from '../../utils/responsive';
import { useAppSelector } from '../../store/hooks/useAppSelector';
import { RootState } from '../../store/types/rootState';
import { useGenerateTicketsMutation, usePaymentCommitTransactionMutation, useRejectPurchaseOrderMutation } from '../../store/api';
import { enqueueSnackbar } from 'notistack';
import { GeneratedTickets } from '../raffle/tickets/generatedTickets';

interface IPurchaseOrderProps {
  purchaseOrders: IPurchaseOrder[];
  selectedRaffleId: string;
  refetch: () => void;
}

export const PurchaseOrderTable = ({ purchaseOrders, selectedRaffleId, refetch }: IPurchaseOrderProps) => {
  const [statusFilter, setStatusFilter] = useState('');
  const [paymentMethodFilter, setPaymentMethodFilter] = useState('');
  const [emailFilter, setEmailFilter] = useState('');
  const [ticketNumbersFilter, setTicketNumbersFilter] = useState('');
  const [nameFilter, setNameFilter] = useState('');
  const [phoneFilter, setPhoneFilter] = useState('');
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);  
  const [page, setPage] = useState(0);
  const rowsPerPage = 20;

  const isMobile = useIsMobileView();
  const [openImageModal, setOpenImageModal] = React.useState(false);
  const [openConfirmPurchaseOrderRejectModal, setOpenConfirmPurchaseOrderRejectModal] = React.useState(false);
  const [openSingleImageModal, setOpenSingleImageModal] = React.useState(false);
  const [selectedImage, setSelectedImage] = React.useState<string | null>(null);
  const [selectedTickets, setSelectedTickets] = React.useState<number[] | undefined>();
  const loggedInAccountId = useAppSelector(
    ({ account }: RootState) => account.id,
  );


  const handleValidateStatus = (order: IPurchaseOrder) => {
    if (
      order.paymentMethod === 'transfer' &&
      order.status === 'TRANSFER_PAYMENT_CREATED'
    ) {
      setOrderId(order.id)
      setSelectedImage(`https://storage.googleapis.com/${loggedInAccountId}/raffles/${selectedRaffleId}/vouchers/${order.id}`);
      setSelectedTickets(order.selectedTickets);
      setOpenImageModal(true);
    }
  };

  const handleCloseImageModal = () => {
      setSelectedImage(null);
      setSelectedTickets(undefined);
      setOpenImageModal(false);
  };
  
  const handleCloseSingleImageModal = () => {
      setSelectedImage(null);
      setSelectedTickets(undefined);
      setOpenSingleImageModal(false);
  };

  const [
    paymentCommit, { data, isLoading: isLoadingCommitTransaction },
  ] = usePaymentCommitTransactionMutation();

  const [
    rejectPurchaseOrder, { data: rejectPurchaseOrderData, isLoading: isLoadingPurchaseOrderRejection },
  ] = useRejectPurchaseOrderMutation();



  const [
    generateTickets,
    {
      data: generateTicketsResponse,
      isLoading: isLoadingGenerateTickets,
    },
  ] = useGenerateTicketsMutation();


  useEffect(() => {
    if (rejectPurchaseOrderData) {
      enqueueSnackbar("Orden de compra rechazada!", {
        variant: "info",
        anchorOrigin: {
          horizontal: "right",
          vertical: "top",
        },
      });
      setOpenConfirmPurchaseOrderRejectModal(false)
      setOpenSingleImageModal(false)
      setOpenImageModal(false)
      refetch();
    } 
  }, [rejectPurchaseOrderData]);


  useEffect(() => {
    if (data?.purchaseOrder && data.status !== "FAILED") {
      generateTickets({
        config: {
          purchaseOrderId: data.purchaseOrder,
          accountId: loggedInAccountId,
        },
        raffleId: selectedRaffleId,
      });
    } else if (data?.status === "FAILED") {
      enqueueSnackbar("Error al momento validar el pago!", {
        variant: "error",
        anchorOrigin: {
          horizontal: "right",
          vertical: "top",
        },
      });
    }
  }, [data, generateTickets, loggedInAccountId, selectedRaffleId]);

  useEffect(() => {
    if (generateTicketsResponse && generateTicketsResponse.status === "SUCCESSFUL") {
      enqueueSnackbar("Tickets generados exitosamente!", {
        variant: "info",
        anchorOrigin: {
          horizontal: "right",
          vertical: "top",
        },
      }); 
      refetch();
      setOpenImageModal(false);
    } else if (generateTicketsResponse && generateTicketsResponse.status === "PROCESS_INITIALIZED") {
      enqueueSnackbar(`Se están generando los tickets, enviaremos el correo al usuario con sus números.`, {
        variant: "info",
        anchorOrigin: {
          horizontal: "right",
          vertical: "top",
        },
      }); 
    }
  }, [setOpenImageModal, generateTicketsResponse])



  const [orderId, setOrderId] = useState<string>("")

  const handleCommitTransaction = () => {
    paymentCommit({
      conf: {
        paymentMethodId: 'transfer',
        orderId,
        password
      },
      raffleId: selectedRaffleId,
    });
  }
  
  const handlePurchaseOrderReject = () => {
    rejectPurchaseOrder({
      raffleId: selectedRaffleId,
      purchaseOrderId: orderId
    });
  }

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const [password, setPassword] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(false);
  const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };


  const handleStartDateChange = (date: Date | null) => {
    if (date) {
      const jsDate = new Date(date);
      jsDate.setHours(0, 0, 0, 0);
      setStartDate(jsDate);
    } else {
      setStartDate(null);
    }
    setPage(0);
  };
  
  const handleEndDateChange = (date: Date | null) => {
    if (date) {
      setEndDate(date);
    } else {
      setEndDate(null);
    }
    setPage(0);
  };


  const handleStatusChange = (event: any) => {
    setStatusFilter(event.target.value);
    setPage(0);
  };

  const handlePaymentMethodChange = (event: any) => {
    setPaymentMethodFilter(event.target.value);
    setPage(0);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailFilter(event.target.value.toLowerCase());
    setPage(0);
  };
  
  const handleTicketNumbersChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTicketNumbersFilter(event.target.value.toLowerCase());
    setPage(0);
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNameFilter(event.target.value.toLowerCase());
    setPage(0);
  };
  
  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneFilter(event.target.value.toLowerCase());
    setPage(0);
  };

  const filteredData = purchaseOrders
  .filter((order: IPurchaseOrder) => {
    if (statusFilter && order.status !== statusFilter) {
      return false;
    }
    if (paymentMethodFilter && order.paymentMethod !== paymentMethodFilter) {
    return false;
    }
    if (emailFilter && !order.purchaser.email.toLowerCase().includes(emailFilter)) {
      return false;
    }
    if (nameFilter && !order.purchaser.name.toLowerCase().includes(nameFilter)) {
      return false;
    }
    if (phoneFilter && !order.purchaser.phone.toLowerCase().includes(phoneFilter)) {
      return false;
    }
    if (ticketNumbersFilter && `${order.ticketQuantity}` != ticketNumbersFilter) {
      return false;
    }
    if (startDate && dayjs(order.createdDate) < dayjs(startDate).hour(0).minute(0).second(0)) {
      return false;
    }
    if (endDate && dayjs(order.createdDate) > dayjs(endDate).hour(23).minute(59).second(59)) {
      return false;
    }
    return true;
  })
  .sort((a: IPurchaseOrder, b: IPurchaseOrder) => {
    return new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime();
  });

  const [openViewNumbersModal, setOpenViewNumbersModal] = React.useState(false);
  const [generatedNumbers, setGeneratedNumbers] = useState<number[]>([]);

  const handleViewGeneratedNumbers = (order: IPurchaseOrder) => {
    if (
      order.paymentMethod === 'transfer' &&
      order.status === 'PROCESS_FINISHED'
    ) {
      setOrderId(order.id)
      if (order.selectedTickets) {
        setGeneratedNumbers(order.selectedTickets)
      }
      setOpenViewNumbersModal(true);
    }
  };

    function formatCurrency(order: IPurchaseOrder, paymentMethod: string): string {
      const usd = paymentMethod === 'paypal' || paymentMethod === 'transfer';
      return new Intl.NumberFormat(usd ? 'en-US' : 'es-CL', {
        style: 'currency',
        currency: usd ? 'USD' : 'CLP',
        currencyDisplay: 'code',
      }).format(usd ? selectedRaffleId === 'a123f062-809b-4a51-a723-d9076fffa3a0' ? order.totalAmountUSD / 2 : order.totalAmountUSD : order.totalAmount);
    }
  const pageCount = Math.ceil(filteredData.length / rowsPerPage);

  const currentPageData = filteredData.slice(page * rowsPerPage, (page + 1) * rowsPerPage);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickViewSomething = (order: IPurchaseOrder) => {
    if (
      order.paymentMethod === 'transfer'
    ) {
      setSelectedImage(`https://storage.googleapis.com/${loggedInAccountId}/raffles/${selectedRaffleId}/vouchers/${order.id}`);
      setOpenSingleImageModal(true);
    }
  }

  return (
    <Box mt={2}>
      <Dialog
          open={openViewNumbersModal}
          onClose={ () => setOpenViewNumbersModal(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <IconButton
          aria-label="close"
          onClick={() => setOpenViewNumbersModal(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
          <DialogTitle id="alert-dialog-title">
            {"Numeros generados para la orden de compra"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
                <GeneratedTickets generatedTickets={generatedNumbers}></GeneratedTickets>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      <Card variant="outlined">
        <CardContent>
          {purchaseOrders.length === 0 ? (
            <Typography variant="body1">No hay ordenes de compra</Typography>
          ) : (
            <>
              <Box paddingBottom={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={2}>
                    <FormControl fullWidth>
                      <InputLabel>Estado</InputLabel>
                      <Select
                        label="Estado"
                        value={statusFilter}
                        onChange={handleStatusChange}
                        fullWidth
                      >
                        <MenuItem value="">Todos</MenuItem>
                        {Array.from(
                          new Set(
                            purchaseOrders.map(
                              (order: IPurchaseOrder) => order.status
                            )
                          )
                        ).map((status) => (
                          <MenuItem key={status} value={status}>
                            {PURCHASE_ORDER_STATUS.find(pos => pos.id === status)?.title}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  {!isMobile && <>
                  
                    <Grid item xs={12} sm={12} md={2}>
                      <FormControl fullWidth>
                        <InputLabel>Método de pago</InputLabel>
                        <Select
                          label="Método de pago"
                          value={paymentMethodFilter}
                          onChange={handlePaymentMethodChange}
                          fullWidth
                        >
                          <MenuItem value="">Todos</MenuItem>
                          {Array.from(
                            new Set(
                              purchaseOrders.map(
                                (order: IPurchaseOrder) => order.paymentMethod
                              )
                            )
                          ).map((paymentMethod) => (
                            <MenuItem key={paymentMethod} value={paymentMethod}>
                              {PAYMENT_METHODS_ICONS("24px").find(paymentMethodE => paymentMethodE.id === paymentMethod)?.icon}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </>}
                  <Grid item xs={12} sm={12} md={3}>
                    <TextField
                      label="Filtrar por email"
                      variant="outlined"
                      value={emailFilter}
                      onChange={handleEmailChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={3}>
                    <TextField
                      label="Filtrar por nombre"
                      variant="outlined"
                      value={nameFilter}
                      onChange={handleNameChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={3}>
                    <TextField
                      label="Filtrar por teléfono"
                      variant="outlined"
                      value={phoneFilter}
                      onChange={handlePhoneChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={1}>
                    <TextField
                      label="# tickets"
                      variant="outlined"
                      value={ticketNumbersFilter}
                      onChange={handleTicketNumbersChange}
                      fullWidth
                    />
                  </Grid>
                  {!isMobile && <>
                  
                    <Grid item xs={12} sm={12} md={2}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                        sx={{
                          width: "100%"
                        }}
                          label="Fecha de Creación"
                          value={startDate}
                          onChange={(date: Date | null) => {
                            handleStartDateChange(date);
                          }}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={12} md={2}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                        sx={{
                          width: "100%"
                        }}
                          label="Hasta"
                          value={endDate}
                          onChange={(date: Date | null) => {
                            handleEndDateChange(date);
                          }}
                        />
                      </LocalizationProvider>
                    </Grid>
                  </>}
                  <Grid item xs={12} sm={12} md={1}>
                    <Box height={"100%"} width={"100%"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                      <Button onClick={refetch} variant='contained' size='small'>Recargar</Button>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={1}>
                    <Box height={"100%"} width={"100%"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                      <InfoOutlinedIcon onClick={handleClickOpen} fontSize='large' sx={{
                        cursor: "pointer"
                      }} />
                    </Box>
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                      >
                        <IconButton
                        aria-label="close"
                        onClick={() => setOpen(false)}
                        sx={{
                          position: 'absolute',
                          right: 8,
                          top: 8,
                          color: (theme) => theme.palette.grey[500],
                        }}
                      >
                        <Close />
                      </IconButton>
                        <DialogTitle id="alert-dialog-title">
                          {"Estados de pago"}
                        </DialogTitle>
                        <DialogContent>
                          <DialogContentText id="alert-dialog-description">
                              {PURCHASE_ORDER_STATUS.map(({ id, title, description }) => (
                              <ListItem key={id}>
                                  <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={4} lg={4} key={id}>
                                      <Chip
                                        label={title}
                                        color={getStatusColor(id)}
                                        variant="outlined"
                                        sx={{
                                          width: '100%',
                                          marginRight: 2,
                                        }}
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={8} lg={8} key={id}>
                                      <Typography>{description}</Typography>
                                    </Grid>
                                  </Grid>
                                </ListItem>
                              ))}
                          </DialogContentText>
                        </DialogContent>
                      </Dialog>
                    <Dialog
                        open={openConfirmPurchaseOrderRejectModal}
                        onClose={ () => setOpenConfirmPurchaseOrderRejectModal(false)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                      >
                        <IconButton
                        aria-label="close"
                        onClick={() => setOpenConfirmPurchaseOrderRejectModal(false)}
                        sx={{
                          position: 'absolute',
                          right: 8,
                          top: 8,
                          color: (theme) => theme.palette.grey[500],
                        }}
                      >
                        <Close />
                      </IconButton>
                        <DialogTitle id="alert-dialog-title">
                          {"Confirmar rechazo de la orden de compra"}
                        </DialogTitle>
                        <DialogContent>
                          <DialogContentText id="alert-dialog-description">
                              Seguro que quiere rechazar la orden de compra? Esta acción no se puede deshacer
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button variant='contained' onClick={handlePurchaseOrderReject}>Confirmar</Button>
                        </DialogActions>
                      </Dialog>
                  </Grid>
                </Grid>
              </Box>
              <Paper sx={{ width: "100%", overflow: "hidden" }}>
                <TableContainer sx={{ maxHeight: "60vh" }}>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          style={{
                            backgroundColor: "black",
                            color: "white",
                            whiteSpace: "nowrap",
                          }}
                        >
                          fecha
                        </TableCell>
                        <TableCell
                          style={{
                            backgroundColor: "black",
                            color: "white",
                            whiteSpace: "nowrap",
                          }}
                        >
                          # tickets
                        </TableCell>
                        <TableCell
                          style={{
                            backgroundColor: "black",
                            color: "white",
                            whiteSpace: "nowrap",
                          }}
                        >
                          monto
                        </TableCell>
                        
                          <TableCell
                            style={{
                              backgroundColor: "black",
                              color: "white",
                              whiteSpace: "nowrap",
                            }}
                          >
                            método de pago
                          </TableCell>
                            
                          <TableCell
                            style={{
                              backgroundColor: "black",
                              color: "white",
                              whiteSpace: "nowrap",
                            }}
                          >
                            nombre
                          </TableCell>
                        
                        <TableCell
                          style={{
                            backgroundColor: "black",
                            color: "white",
                            whiteSpace: "nowrap",
                          }}
                        >
                          email
                        </TableCell>
                    
                        <TableCell
                          style={{
                            backgroundColor: "black",
                            color: "white",
                            whiteSpace: "nowrap",
                          }}
                        >
                          teléfono
                        </TableCell>
                        <TableCell
                          style={{
                            backgroundColor: "black",
                            color: "white",
                            whiteSpace: "nowrap",
                          }}
                        >
                          estado
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {currentPageData.map(
                        (order: IPurchaseOrder, index: number) => (
                          <TableRow key={index}>
                            <TableCell sx={{
                              whiteSpace: "nowrap",
                            }}>
                              {dayjs(order.createdDate).format(
                                "YYYY-MM-DD HH:mm:ss"
                              )}
                            </TableCell>
                            <TableCell>
                              <Box>
                                <Chip 
                                label={order.ticketQuantity}
                                color={getStatusColor(order.status)}
                                onClick={() => handleViewGeneratedNumbers(order)}
                                variant="outlined"
                                sx={{
                                  width: '100%',
                                }} />
                              </Box>
                            </TableCell>
                            <TableCell>
                              {formatCurrency(order, order.paymentMethod)}
                            </TableCell>
                            <TableCell>
                              <Button onClick={() => handleClickViewSomething(order)}>
                                {PAYMENT_METHODS_ICONS("25px").find(
                                  (icon) => icon.id === order.paymentMethod
                                )?.icon}
                              </Button>
                            </TableCell>
                            <TableCell>{order.purchaser.name}</TableCell>
                            <TableCell>{order.purchaser.email}</TableCell>
                            <TableCell>{order.purchaser.phone}</TableCell>
                            <TableCell>
                            <Grid container>
                                <Grid item pl={2} width={'100%'}>
                              <Typography variant="body2">
                                <Chip
                                  label={PURCHASE_ORDER_STATUS.find(pos => pos.id === order.status)?.title}
                                  color={getStatusColor(order.status)}
                                  onClick={() => handleValidateStatus(order)}
                                  variant="outlined"
                                  sx={{
                                    width: '100%',
                                  }}
                                />
                              </Typography>
                                </Grid>
                              </Grid>
                            </TableCell>
                          </TableRow>
                        )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
              <Box mt={2} display={"flex"} justifyContent={"center"}>
                <Pagination
                  count={pageCount}
                  page={page + 1}
                  onChange={(event, value) => setPage(value - 1)}
                  color="primary"
                />
              </Box>
            </>
          )}
        </CardContent>
      </Card>
      <Dialog
        open={openSingleImageModal}
        onClose={handleCloseSingleImageModal}
        aria-labelledby="image-modal-title"
        maxWidth="md"
        fullScreen={isMobile}
      >
        <IconButton
          aria-label="close"
          onClick={handleCloseSingleImageModal}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Grid container>
          <Grid
              item
            >
              {selectedImage && (
                <img
                  src={selectedImage}
                  alt=''
                  style={{ width: '300px' }}
                />
              )}
            </Grid>
            <Grid
              item
            >
              <Button onClick={handleCloseSingleImageModal}>
                Cerrar
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openImageModal}
        onClose={handleCloseImageModal}
        aria-labelledby="image-modal-title"
        maxWidth="md"
        fullScreen={isMobile}
      >
         <IconButton
          aria-label="close"
          onClick={() => setOpenImageModal(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle>Validar comprobante</DialogTitle>
        <DialogContent>
          <Grid container>
          <Grid
              item
              sx={{
                width: "100%",
              }}
            >
              {selectedImage && (
                <img
                  src={selectedImage}
                  alt=''
                  style={{ width: '300px' }}
                />
              )}
              </Grid>                        
           <Grid
              item
              sx={{
                width: "100%",
              }}
            >
              {selectedTickets && selectedTickets.length > 0  && <Box m={2}>
                <Typography fontWeight={"bold"} mb={2}>
                  Tickets seleccionados
                </Typography>
                {selectedTickets?.map( t => (
                <>
                    <Button sx={{ m: 1 }}> {t} </Button>
                  </>
                )) }
              </Box>}

              {!isLoadingCommitTransaction && !isLoadingGenerateTickets && <Button
                fullWidth
                type="submit"
                color="primary"
                disabled={
                  password === ""
                }
                sx={{
                  opacity: password === "" ? 0.3 : 1,
                  backgroundColor: "#FFF !important",
                  mt: 2,
                  mb: 5
                }}
                variant="contained"
                
                onClick={() => setOpenConfirmPurchaseOrderRejectModal(true)}
              >
                <DeleteForever sx={{ fontSize: 25 }}></DeleteForever>
                <Typography color={"#000"} ml={1} fontWeight={"bold"}>
                  Cancelar orden de compra
                </Typography>
              </Button>}

              <Typography>
                Asegurese de validar el compobante correctamente antes de generar los tickets.
              </Typography>
              <Typography>
                Esta acción requiere contraseña.
              </Typography>

              <FormControl sx={{ width: "100%", mt: 2 }} variant="outlined">
                <InputLabel htmlFor="password-generate-tickets">
                  Password
                </InputLabel>
                <OutlinedInput
                  id="password-generate-tickets"
                  type={showPassword ? "text" : "password"}
                  onChange={handleChangePassword}
                  fullWidth
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
              </FormControl>
          
              {!isLoadingCommitTransaction && !isLoadingGenerateTickets && <Button
                fullWidth
                type="submit"
                color="primary"
                disabled={
                  password === ""
                }
                sx={{
                  opacity: password === "" ? 0.3 : 1,
                  backgroundColor: "#FFF !important",
                  mt: 2,
                }}
                variant="contained"
                startIcon={
                  PAYMENT_METHODS_ICONS("30px").find(
                    (element) => element.id === "transfer"
                  )?.icon
                }
                onClick={handleCommitTransaction}
              >
                <Typography color={"#000"} ml={1} fontWeight={"bold"}>
                  Generar tickets
                </Typography>
              </Button>}
              {isLoadingCommitTransaction && (<LoadingComponent message="Confirmando orden de compra" />)}
              {isLoadingGenerateTickets && (<LoadingComponent message="Generando tickets" />)}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Box>
  );
};


interface LoadingComponentProps {
  message: string;
}
const LoadingComponent = ({ message }: LoadingComponentProps) => (
  <Box
    m={3}
    display="flex"
    flexDirection="column"
    alignItems="center"
    width="100%"
    justifyContent="center"
  >
    <Typography>{message}</Typography>
    <CircularProgress
      variant="indeterminate"
      sx={{
        color: "#000",
      }}
    />
  </Box>
);

const getStatusColor = (status: string): "success" | "error" | "info" => {
  switch (status) {
    case 'PROCESS_FINISHED':
      return 'success';
    case 'PAYMENT_FAILED':
    case 'PURCHASE_ORDER_REJECTED':
    case 'CASH_PAYMENT_BAD_CREDENTIALS':
      return 'error';
    default:
      return 'info';
  }
};
