import { RootState } from "../../store/types/rootState";
import { useAppSelector } from "../../store/hooks/useAppSelector";
import NotFoundPage from "../layout/404";

export interface PrivateRouteProps {
  children: React.ReactElement;
}

const PrivateRoute = ({ children }: PrivateRouteProps) => {

  const isLoggedIn = useAppSelector(
    ({ authentication }: RootState) => authentication.isLoggedIn,
  );
  
  const profile = useAppSelector(
    ({ user }: RootState) => user.profile,
  );

  
  


  

  if (!isLoggedIn || (profile !== 'admin' && children.key === 'dashboard')) {
    return <NotFoundPage/>;
  }

  return children;
};
export default PrivateRoute;
