import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Grid,
  Checkbox,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Paper,
} from "@mui/material";
import { useAppSelector } from "../../../store/hooks/useAppSelector";
import { RootState } from "../../../store/types/rootState";
import { IRaffle } from "../../../store/interfaces/IRaffle";
import { BANK_ACCOUNTS, BankAccountItem } from "../../../constants/bankAccounts";
import { banks } from "../../../constants/banks";

interface BankAccountsProps {
  raffle: IRaffle;
}

const BankAccountForm = ({
  selectedBank,
  handleChange,
  addConvertion,
  setAddConvertion,
}: any) => (
  <>
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <TextField
          label="Nombre"
          variant="outlined"
          fullWidth
          value={selectedBank.name || ""}
          onChange={handleChange}
          name="name"
          margin="normal"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label="DNI"
          variant="outlined"
          fullWidth
          value={selectedBank.dni || ""}
          onChange={handleChange}
          name="dni"
          margin="normal"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label="Banco"
          variant="outlined"
          fullWidth
          value={selectedBank.bank || ""}
          onChange={handleChange}
          name="bank"
          margin="normal"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label="Tipo de cuenta"
          variant="outlined"
          fullWidth
          value={selectedBank.accountType || ""}
          onChange={handleChange}
          name="accountType"
          margin="normal"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label="Número de cuenta"
          variant="outlined"
          fullWidth
          value={selectedBank.accountNumber || ""}
          onChange={handleChange}
          name="accountNumber"
          margin="normal"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label="Precio del ticket descripción"
          variant="outlined"
          fullWidth
          value={selectedBank.ticketPrice || ""}
          onChange={handleChange}
          name="ticketPrice"
          margin="normal"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label="Precio ticket"
          variant="outlined"
          fullWidth
          type="number"
          value={selectedBank.ticketPriceAmount?.toString() || ""}
          onChange={handleChange}
          name="ticketPriceAmount"
          margin="normal"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label="Moneda"
          variant="outlined"
          fullWidth
          value={selectedBank.ticketCurrency || ""}
          onChange={handleChange}
          name="ticketCurrency"
          margin="normal"
        />
      </Grid>

      <Grid item xs={12}>
        <Checkbox
          checked={addConvertion}
          onChange={() => {
            setAddConvertion(!addConvertion);
            if (!addConvertion) {
              selectedBank.conversion = { dolarPrice: 0, dolarPriceCurrency: "BS" };
            } else {
              selectedBank.conversion = undefined;
            }
          }}
          inputProps={{ "aria-label": "controlled" }}
        />
        <Typography display={"inline"}>Agregar conversión</Typography>
      </Grid>

      {addConvertion && selectedBank.conversion && (
        <>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Precio del dolar"
              variant="outlined"
              fullWidth
              value={selectedBank.conversion.dolarPrice || ""}
              onChange={handleChange}
              name="conversion.dolarPrice"
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Moneda"
              variant="outlined"
              fullWidth
              value={selectedBank.conversion.dolarPriceCurrency || ""}
              onChange={handleChange}
              name="conversion.dolarPriceCurrency"
              margin="normal"
            />
          </Grid>
        </>
      )}
    </Grid>
  </>
);

export const BankAccounts = ({ raffle }: BankAccountsProps) => {
  const accountId = useAppSelector(({ account }: RootState) => account.id);
  const config = BANK_ACCOUNTS.find((bankAccount) => bankAccount.accountId === accountId);

  const [open, setOpen] = useState(false);
  const [selectedBank, setSelectedBank] = useState<BankAccountItem | null>(null);
  const [addConvertion, setAddConvertion] = useState<boolean>(false);

  const handleOpen = (bank: BankAccountItem) => {
    setSelectedBank(bank);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    if (selectedBank) {
      const updatedBank = { ...selectedBank };
      const keys = name.split(".");
      let temp = updatedBank as any;
      keys.forEach((key: any, index: any) => {
        if (index === keys.length - 1) {
          temp[key] = value;
        } else {
          temp = temp[key];
        }
      });
      setSelectedBank(updatedBank);
    }
  };

  const handleDelete = (accountNumber: string) => {
    if (config) {
      const updatedBankAccounts = config.bankAccounts.filter(
        (bank) => bank.accountNumber !== accountNumber
      );
      console.log("Deleted account:", accountNumber);
    }
  };

  const handleSave = () => {
    console.log("Saved bank account:", selectedBank);
    setOpen(false);
  };

  return (
    <Box>
      <Typography variant="h6">Cuentas bancarias</Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Banco</TableCell>
              <TableCell>Nombre</TableCell>
              <TableCell># de cuenta</TableCell>
              <TableCell>Precio del ticket</TableCell>
              <TableCell>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {config?.bankAccounts.map((bank) => (
              <TableRow key={bank.accountNumber}>
                <TableCell>{banks.find(bankE => bankE.id === bank.bankId)?.icon}</TableCell>
                <TableCell>{bank.bank}</TableCell>
                <TableCell>{bank.accountNumber}</TableCell>
                <TableCell>{bank.ticketPrice}</TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    onClick={() => handleOpen(bank)}
                    color="primary"
                  >
                    Edit
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => handleDelete(bank.accountNumber || '')}
                    color="secondary"
                    style={{ marginLeft: "8px" }}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={open} onClose={handleClose} maxWidth={"md"} fullWidth>
        <DialogTitle>Editar cuenta</DialogTitle>
        <DialogContent>
          {selectedBank && (
            <BankAccountForm
              selectedBank={selectedBank}
              handleChange={handleChange}
              addConvertion={addConvertion}
              setAddConvertion={setAddConvertion}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
