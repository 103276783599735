import { useEffect, useState } from "react";
import { useGetPurchasersQuery } from "../../store/api";
import { Box, TextField, Typography } from "@mui/material";
import { useIsMobileView } from "../../utils/responsive";
import WinnerSearchResults from "../raffle/winnerSearch";
import { useAppSelector } from "../../store/hooks/useAppSelector";
import { RootState } from "../../store/types/rootState";



function useDebounce(value: string, delay: number): string {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}


export const WinnersPage = () => {


  const selectedRaffle = useAppSelector(
    ({ selectedRaffle }: RootState) => selectedRaffle,
  );


  const { data: purchasers, refetch } = useGetPurchasersQuery(selectedRaffle.id, {
    skip: selectedRaffle.id === 'default',
  });


  const [open, setOpen] = useState(false);

    const [searchTerm, setSearchTerm] = useState("");
    const debouncedSearchTerm = useDebounce(searchTerm, 500);

   
  const isMobileView = useIsMobileView();

  return (
    <Box paddingX={isMobileView ? 2 : 10} marginTop={2}>
      <Typography variant="h6" fontFamily={"Georgia"} paddingBottom={2} >
        Ganadores
      </Typography>


    <Box m={10}>

    <TextField
      InputProps={{ inputProps: { min: 0, max: 10000 } }}
      id="ticket"
      label="Número ganador"
      type="number"
      fullWidth
      variant="standard"
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
    />

      {purchasers && selectedRaffle.id != 'default' ? <WinnerSearchResults setOpen={setOpen} raffleId={selectedRaffle.id} ticket={Number.parseInt(debouncedSearchTerm)} /> : null}
    </Box>

    </Box>
  );
};
