import { AddCircle , Dashboard, Home, Paid, EmojiPeople, SettingsApplications } from '@mui/icons-material';
export const PAGES = [
  {
    name: "Dashboard",
    url: "/dashboard",
    icon: (
      <Paid fontSize="medium" />
    ),
    availableProfiles: ["admin"]
  },
  {
    name: "Ordenes de compra",
    url: "/purchaseOrders",
    icon: (
      <Paid fontSize="medium" />
    ),
    availableProfiles: ["admin", "support"]
  },
  {
    name: "Compradores",
    url: "/purchasers",
    icon: (
      <EmojiPeople fontSize="medium" />
    ),
    availableProfiles: ["admin", "support"]
  },
  {
    name: "Ganadores",
    url: "/winners",
    icon: (
      <Paid fontSize="medium" />
    ),
    availableProfiles: ["admin"]
  },
  {
    name: "Números",
    url: "/soldTickets",
    icon: (
      <Paid fontSize="medium" />
    ),
    availableProfiles: ["admin"]
  },
  {
    name: "Configuración",
    url: "/configuration",
    icon: (
      <SettingsApplications fontSize="medium" />
    ),
    availableProfiles: ["admin"]
  },
  {
    name: "Crear rifa",
    url: "/create",
    icon: (
      <AddCircle fontSize="medium" />
    ),
    availableProfiles: ["admin"]
  },
];