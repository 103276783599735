import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  SelectChangeEvent,
  ListItemIcon,
} from "@mui/material";
import { useAppSelector } from "../../../store/hooks/useAppSelector";
import { RootState } from "../../../store/types/rootState";
import { Email, WhatsApp } from "@mui/icons-material"; 
import { IRaffle } from "../../../store/interfaces/IRaffle";

interface ConfigurationProps {
  raffle: IRaffle;
}

export const Configuration = ({ raffle }: ConfigurationProps) => {
  
  const [raffleConfig, setRaffleConfig] = useState<any>({
    ticketsSender: "email",
    showPurchaserTop: false,
    showTicketsFinder: false,
    showProgressBar: false,
  });

  const [newBlockedNumber, setNewBlockedNumber] = useState<string>("");

  // Sample list of available numbers
  const [availableNumbers, setAvailableNumbers] = useState<string[]>([]);

  // Handle input changes
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = event.target;
    if (type === "checkbox") {
      setRaffleConfig((prevState: any) => ({
        ...prevState,
        [name]: checked,
      }));
    } else {
      setRaffleConfig((prevState: any) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    console.log(raffleConfig);
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        p: 3,
        borderRadius: 2,
        boxShadow: 3,
        backgroundColor: "background.paper",
        maxWidth: 600,
        margin: "0 auto",
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel>Enviar tickets</InputLabel>
            <Select
              value={raffleConfig.ticketsSender}
              onChange={(e) => setRaffleConfig({ ...raffleConfig, ticketsSender: e.target.value })}
              disabled
              label="Envío de tickets"
              sx={{
                borderRadius: 1,
                "& .MuiOutlinedInput-root": {
                  borderRadius: 1,
                },
              }}
            >
              <MenuItem value="email" selected>
                <ListItemIcon>
                  <Email sx={{ color: "#0288d1" }} />
                </ListItemIcon>
                Por Email
              </MenuItem>
              <MenuItem value="whatsapp" disabled>
                <ListItemIcon>
                  <WhatsApp sx={{ color: "#25D366" }} />
                </ListItemIcon>
                Por WhatsApp
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {/* Show Purchaser Top */}
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={raffleConfig.showPurchaserTop}
                onChange={handleInputChange}
                name="showPurchaserTop"
                sx={{
                  "& .MuiSvgIcon-root": { fontSize: 26 },
                }}
              />
            }
            label={<Typography variant="body1">Mostrar top 3 compradores</Typography>}
          />
        </Grid>
     
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={raffleConfig.showProgressBar}
                onChange={handleInputChange}
                name="showProgressBar"
                sx={{
                  "& .MuiSvgIcon-root": { fontSize: 26 },
                }}
              />
            }
            label={<Typography variant="body1">Mostrar progreso</Typography>}
          />
        </Grid>

        {/* Show Tickets Finder */}
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={raffleConfig.showTicketsFinder}
                onChange={handleInputChange}
                name="showTicketsFinder"
                sx={{
                  "& .MuiSvgIcon-root": { fontSize: 26 },
                }}
              />
            }
            label={<Typography variant="body1">Mostrar buscador de tickets</Typography>}
          />
        </Grid>


        {/* Submit Button */}
        <Grid item xs={12}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{
              textTransform: "none",
              borderRadius: 1,
              fontSize: 16,
              "&:hover": {
                backgroundColor: "#0069d9",
              },
            }}
          >
            Guardar configuración
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
