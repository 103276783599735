import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  SelectChangeEvent,
  ListItemIcon,
  Alert,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useAppSelector } from "../../../store/hooks/useAppSelector";
import { RootState } from "../../../store/types/rootState";
import { Delete, Email, Lock, WhatsApp } from "@mui/icons-material"; 
import { IRaffle } from "../../../store/interfaces/IRaffle";
import { useBlockNumberMutation, useGetBlockedNumbersByRaffleIdQuery, useLazyGetRaffleWinnerQuery, useUnblockNumberMutation } from "../../../store/api";
import { enqueueSnackbar } from "notistack";

interface BlockedNumbersProps {
  raffle: IRaffle;
}

export const BlockedNumbers = ({ raffle }: BlockedNumbersProps) => {
  const [getAssignedNumber] = useLazyGetRaffleWinnerQuery();
  const { data: currentBlockedNumbers, isLoading: loadingNewBlockedNumbers, refetch: refetchGetCurrentBlockedNumbers } = useGetBlockedNumbersByRaffleIdQuery(raffle.id, { refetchOnFocus: false });
  const [blockNumber, {data: blockNumberResponse}] = useBlockNumberMutation();
  const [unblockNumber, {data: unblockNumberResponse}] = useUnblockNumberMutation();
  const [newBlockedNumber, setNewBlockedNumber] = useState<number | undefined>();
  const [newUnblockedNumber, setNewUnblockedNumber] = useState<number | undefined>();
  const [openUnblockNumberConfirm, setOpenUnblockNumberConfirm] = useState<boolean>(false);

  useEffect(() =>  {
    refetchGetCurrentBlockedNumbers()
  }, [])
  
  useEffect(() =>  {
    if (blockNumberResponse) {
      console.log(blockNumberResponse)

      enqueueSnackbar(`El numero ${newBlockedNumber} se ha bloqueado correctamente`, {
        variant: "success",
        anchorOrigin: {
          horizontal: "right",
          vertical: "top"
        },
      })
      setNewBlockedNumber(undefined)
      refetchGetCurrentBlockedNumbers()
    }

  }, [blockNumberResponse])

  useEffect(() =>  {
    if (unblockNumberResponse) {
      console.log(unblockNumberResponse)

      enqueueSnackbar(`El numero ${newBlockedNumber} se ha desbloqueado correctamente`, {
        variant: "success",
        anchorOrigin: {
          horizontal: "right",
          vertical: "top"
        },
      })
      setNewBlockedNumber(undefined)
      setOpenUnblockNumberConfirm(false)
      setNewUnblockedNumber(undefined)
      refetchGetCurrentBlockedNumbers()
    }

  }, [unblockNumberResponse])


  const handleUnblockNumber = () => {
    if (newUnblockedNumber != undefined) {
      unblockNumber({
        newNumber: newUnblockedNumber,
        raffleId: raffle.id
      })
    }
  }


  const handleAddBlockedNumber = async () => {
    if (newBlockedNumber != undefined && newBlockedNumber >= 0) {
      const result = await getAssignedNumber({
        raffleId: raffle.id,
        ticket: newBlockedNumber
      }) as any;

      if (result.isError && result.error?.status === 404) {
        enqueueSnackbar(`El numero ${newBlockedNumber} no se encuentra asignado a nadie`, {
            variant: "info",
            anchorOrigin: {
              horizontal: "right",
              vertical: "top"
            },
          })
          blockNumber({
            newNumber: newBlockedNumber,
            raffleId: raffle.id
          })
      } else if (!result.isError && result.status === 'fulfilled') {
        enqueueSnackbar(`El numero ${newBlockedNumber} se encuentra asignado a ${result.data.name}`, {
          variant: "error",
          anchorOrigin: {
            horizontal: "right",
            vertical: "top"
          },
        })
      } else {
        enqueueSnackbar(`Error ${JSON.stringify(result.error)}`, {
          variant: "error",
          anchorOrigin: {
            horizontal: "right",
            vertical: "top"
          },
        })
      }

    }
  };

  return (
    <Box
      sx={{
        p: 3,
        borderRadius: 2,
        boxShadow: 3,
        backgroundColor: "background.paper",
        maxWidth: 600,
        margin: "0 auto",
      }}
    >

        <Dialog
            sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
            maxWidth="xs"
            open={openUnblockNumberConfirm}
          >
            <DialogTitle>Esta seguro que desea liberar el {newUnblockedNumber}? </DialogTitle>
            <DialogContent>
              <Typography> Esta accion liberará el numero y ahora podría asignarse </Typography>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={() => {
                setNewUnblockedNumber(undefined)
                setOpenUnblockNumberConfirm(false)
              }}>
                Cancel
              </Button>
              <Button onClick={handleUnblockNumber}>Ok</Button>
            </DialogActions>
          </Dialog>


      <Grid container spacing={3}>

        {currentBlockedNumbers && currentBlockedNumbers.length == 0 && <Grid item>
            <Alert variant="outlined" severity="info">
              No hay números bloqueados
            </Alert>
          </Grid>}


          {currentBlockedNumbers && currentBlockedNumbers.length > 0 && <Grid item>
            <Typography pb={2} variant="h5">Números bloqueados (máx 8)</Typography>
            <Stack spacing={2} direction={"column"}>
              {currentBlockedNumbers.map(blockedNumber => (
                <Chip
                size="medium"
                color="primary"
                sx={{ fontSize: 20 }}
                variant="outlined"
                onDelete={() => {
                  setNewUnblockedNumber(blockedNumber)
                  setOpenUnblockNumberConfirm(true)
                }}  
                deleteIcon={<Delete />}
                label={`${blockedNumber}`}></Chip>
              ))}
            </Stack>
          </Grid>}
        
        <Grid item xs={12}>
        <TextField
          label="Agregar nuevo número"
          variant="outlined"
          fullWidth
          inputProps={{ maxLength: raffle.limit.toString().length - 1 }}
          value={newBlockedNumber}
          onChange={(e) => {
            const value = e.target.value.replace(/\D/g, "");
            setNewBlockedNumber(value ? Number(value) : 0);
          }}
          sx={{
            borderRadius: 1,
            "& .MuiOutlinedInput-root": {
              borderRadius: 1,
            },
          }}
        />

          <Button
            disabled={(currentBlockedNumbers && currentBlockedNumbers?.length >= 8) || currentBlockedNumbers?.includes(newBlockedNumber || 0) || newBlockedNumber === undefined}
            variant="contained"
            color="primary"
            sx={{
              mt: 2,
              textTransform: "none",
              borderRadius: 1,
              fontSize: 16,
              "&:hover": {
                backgroundColor: "#0069d9",
              },
              mb: 2,
            }}
            onClick={handleAddBlockedNumber}
            endIcon={<Lock></Lock>}
          >
            Bloquear {newBlockedNumber}
          </Button>
          {currentBlockedNumbers && currentBlockedNumbers?.length >= 8 && <Alert sx={{ fontSize: 16 }} variant="outlined" severity="warning">No se pueden bloquear mas de 8 números, desbloquee uno para continuear</Alert>}
        </Grid>
      </Grid>
    </Box>
  );
};
