import { Box, Typography } from "@mui/material";
import { useIsMobileView } from "../../utils/responsive";
import { Configuration } from "../configuration/modules/GeneralSettings";
import { useAppSelector } from "../../store/hooks/useAppSelector";
import { RootState } from "../../store/types/rootState";
import SettingsMenu from "../configuration/SettingsMenu";


export const ConfigurationPage = () => {

    const selectedRaffle = useAppSelector(
      ({ selectedRaffle }: RootState) => selectedRaffle,
    );
  

  const isMobileView = useIsMobileView();

  return (
    <Box paddingX={isMobileView ? 2 : 10} marginTop={2}>
      <Typography variant="h6" fontFamily={"Georgia"} paddingBottom={2}>
        Configuración de rifa <strong>{selectedRaffle.name}</strong>
      </Typography>
      <Box mx={10}>
        {selectedRaffle.id != 'default' ? <SettingsMenu raffle={selectedRaffle} /> : null}
      </Box>
    </Box>
  );
};
