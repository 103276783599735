import React, { useState } from 'react';
import { Paper, Grid, Chip, Box, TextField, Typography } from '@mui/material';
import { useIsMobileView } from '../../../utils/responsive';
import { getThemeByAccountId } from '../../../utils/styleConstants';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/types/rootState';

interface GeneratedTicketsProps {
  generatedTickets: Array<number>;
}

export const GeneratedTickets = ({ generatedTickets }: GeneratedTicketsProps) => {
  const [ticketFilter, setTicketFilter] = useState<string>('');

  const handleTicketFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTicketFilter(event.target.value.toLowerCase());
  };

  const filteredTickets = generatedTickets.filter((ticketNumber) => {
    return ticketNumber.toString().includes(ticketFilter);
  });

  const accountId = useSelector(({ account }: RootState) => account.id);

  const isMobile = useIsMobileView();

  return (
    <Box height="100%" display={"flex"} justifyContent={"center"}>
        <Grid
          container
          spacing={2}
          mt={2}
          sx={{
            maxHeight: !isMobile ? '30vh' : '65vh',
            overflowY: 'auto',
            width: '100%',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          {filteredTickets.map((ticketNumber) => (
            <Grid item key={ticketNumber}>
              <Chip sx={{
                height: '48px',
                width: '48px',
                borderRadius: '50px',
                "& .MuiChip-label": {
                  textAlign: "center",
                  fontWeight: 'bold',
                  padding: 0,
                }
              }} color="primary" label={<Typography sx={{
                wordBreak: 'keep-all',
                padding: 0,
                fontWeight: 'bold',
                textOverflow: 'inherit !important',
                color: `${getThemeByAccountId(accountId) === 'dark' ? '#000 !important' : '#FFF !important' }`
              }}>
                {ticketNumber.toString().padStart(4, '0')}
              </Typography>}  />
            </Grid>
          ))}
          {filteredTickets.length === 0 && <Grid item>
              <Typography>
                No se encontraron números
              </Typography>
            </Grid>}
        </Grid>
    </Box>
  );
};
