import { IRaffle } from "../interfaces/IRaffle";

export const RaffleStateDefault: IRaffle = {
     id: "default",
     availablePaymentMethods: [],
     awards: [],
     limit: 0,
     name: "default",
     status: "default",
     ticketPrice: 0,
     ticketPriceUSD: 0,
     type: "raffle",
     sorterId: "default",
     bankAccounts: [],
     isPremium: false
};
