import { useGetPurchaseOrdersQuery } from "../../store/api";
import { Box, Typography } from "@mui/material";
import { useIsMobileView } from "../../utils/responsive";
import { PurchaseOrderTable } from "../puchaseOrder/table";
import { useAppSelector } from "../../store/hooks/useAppSelector";
import { RootState } from "../../store/types/rootState";

export const PurchaseOrderPage = () => {
  
  const selectedRaffle = useAppSelector(
    ({ selectedRaffle }: RootState) => selectedRaffle,
  );

  const { data: purchaseOrders, refetch } = useGetPurchaseOrdersQuery(selectedRaffle.id, {
    skip: selectedRaffle.id === 'default',
  });

  const isMobileView = useIsMobileView();

  return (
    <Box paddingX={isMobileView ? 2 : 10} marginTop={2}>
      <Typography variant="h6" fontFamily={"Georgia"} paddingBottom={2} >
        Ordenes de compra
      </Typography>
      {purchaseOrders ? <PurchaseOrderTable purchaseOrders={purchaseOrders} selectedRaffleId={selectedRaffle.id} refetch={refetch} /> : null}
    </Box>
  );
};
