import { useState, useEffect } from "react";
import { Box, Typography, Button, Stack, CircularProgress } from "@mui/material";

const WelcomeBox = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        padding: 3,
      }}
    >
      {/* Título principal */}
      <Typography variant="h4" fontWeight="bold" mb={2}>
        Plataforma de rifas
      </Typography>

      {loading ? (
        <CircularProgress />
      ) : (
        <Box>
          <Typography variant="h5" fontWeight="bold" gutterBottom>
            ¡Ups! Este link ya no está disponible.
          </Typography>
          <Typography variant="body1" mb={2}>
            Pero no te preocupes, síguenos en nuestras redes sociales para más información.
          </Typography>
          <Stack direction="row" spacing={2} justifyContent="center">
            <Button
              variant="contained"
              color="primary"
              href="https://www.instagram.com/viprifas1"
              target="_blank"
            >
              Instagram
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              href="https://viprifas.com"
              target="_blank"
            >
              Visita nuestra web
            </Button>
          </Stack>
        </Box>
      )}
    </Box>
  );
};

export default WelcomeBox;
