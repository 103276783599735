import React, { useState } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Divider,
  ListItemIcon,
  Tooltip,
} from "@mui/material";
import { Configuration } from "./modules/GeneralSettings";
import { IRaffle } from "../../store/interfaces/IRaffle";
import { AddAlert, CreditCard, Numbers, Settings, WorkspacePremium, Star } from "@mui/icons-material";
import { BankAccounts } from "./modules/BankAccounts";
import { BlockedNumbers } from "./modules/BlockedNumbers";
import { PromoEngine } from "./modules/PromoEngine";

const menuItems = [
  { label: "Configuración general", key: "general", premiumConfig: false, icon: <Settings /> },
  { label: "Cuentas bancarias", key: "bank", premiumConfig: false, icon: <CreditCard /> },
  { label: "Números", key: "blocked", premiumConfig: true, icon: <Numbers /> },
  { label: "Promociones", key: "promo", premiumConfig: true, icon: <AddAlert /> },
];

interface ConfigurationProps {
  raffle: IRaffle;
}

export const SettingsMenu = ({ raffle }: ConfigurationProps) => {
  const [selectedMenu, setSelectedMenu] = useState("general");

  return (
    <Box sx={{ display: "flex" }}>
      <Divider />
      <List>
        {menuItems.map((item) => (
          <Tooltip key={item.key} title={item.premiumConfig ? "Funcionalidad Premium" : ""} placement="right">
            <ListItem disablePadding>
              <ListItemButton
                selected={selectedMenu === item.key}
                disabled={item.premiumConfig && !raffle.isPremium}
                onClick={() => setSelectedMenu(item.key)}
                sx={{
                  borderRadius: 1,
                  my: 0.5,
                  "&.Mui-selected": { bgcolor: "primary.main", color: "white" },
                  "&:hover": { bgcolor: "primary.light", color: "white" },
                }}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.label} />
                {item.premiumConfig && !raffle.isPremium && <Star sx={{ pl: 1 }}></Star>}
              </ListItemButton>
            </ListItem>
          </Tooltip>
        ))}
      </List>

      {/* Main Content */}
      <Box sx={{ flexGrow: 1, p: 4 }}>
        {selectedMenu === "general" && <Configuration raffle={raffle} />}
        {selectedMenu === "bank" && <BankAccounts raffle={raffle} />}
        {selectedMenu === "blocked" && <BlockedNumbers raffle={raffle} />}
        {selectedMenu === "promo" && <PromoEngine raffle={raffle} />}
      </Box>
    </Box>
  );
};

export default SettingsMenu;
