import React, { useState, useEffect } from "react";
import { useCloseRaffleMutation, useGetActiveRaffleQuery, useGetRaffleWinnerQuery } from "../../store/api";
import { Box, Button, CircularProgress, Paper, Switch, Typography } from "@mui/material";
import { Email, EmojiEvents, Person, Phone } from "@mui/icons-material";
import { enqueueSnackbar } from "notistack";
import { useAppSelector } from "../../store/hooks/useAppSelector";
import { RootState } from "../../store/types/rootState";

type WinnerSearchProps = {
  raffleId: string;
  setOpen: (value: boolean) => void;
  ticket: number;
};

const WinnerSearchResults: React.FC<WinnerSearchProps> = ({
  raffleId,
  setOpen,
  ticket,
}: WinnerSearchProps) => {

  const [, setFilteredSearchTerm] = useState(raffleId);
  const [sendEmail, setSendEmail] = useState(false);
  const { data, error, isLoading, isFetching } = useGetRaffleWinnerQuery({ raffleId, ticket }, { refetchOnFocus: false });
  const winner = data;


  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSendEmail(event.target.checked);
  };

  const [closeRaffleHandler] = useCloseRaffleMutation();

  const handleCloseRaffle = async () => {
    try {
      const result = await closeRaffleHandler({
        conf: {
          sendEmail,
          winnerEmail: data?.email
        },
        raffleId
      }).unwrap();

      if (result) {
        enqueueSnackbar('Rifa cerrada con exito!', {
          variant: "success",
          anchorOrigin: {
            horizontal: "right",
            vertical: "top"
          },
        })
        setOpen(false);
        setTimeout(() => {
          window.location.reload();  
        }, 1500);
      } else {
        enqueueSnackbar('Error al momento de cerrar la rifa!', {
          variant: "error",
          anchorOrigin: {
            horizontal: "right",
            vertical: "top"
          },
        })  
      }
    } catch (error) {
      enqueueSnackbar('Error al momento de cerrar la rifa!', {
        variant: "error",
        anchorOrigin: {
          horizontal: "right",
          vertical: "top"
        },
      })
    }
  }

  useEffect(() => {
    if (raffleId.length === 0 || raffleId.length > 4) {
      setFilteredSearchTerm(raffleId);
    }
  }, [raffleId]);

  if (isLoading) {
    return <CircularProgress color="primary" />;
  }

  if (isFetching) {
    return <Typography p={4} fontSize={22} color="textPrimary" variant="body1">Buscando ganador...</Typography>;
  }

  if (error || !winner) {
    return <>
      <Typography p={4} fontSize={22} color="textPrimary" variant="body1">No hay ganadores</Typography>
      <Box p={6}>
        <Button onClick={handleCloseRaffle} variant="contained" fullWidth>Cerrar rifa</Button>
      </Box>
    </>
  }

  return (
    <Box display="flex" alignItems="center" sx={{ width: '100%' }} pt={5}>
        <Paper key={winner.email} elevation={3} className="winner-paper" sx={{ width: '100%' }}>
          <Box p={2} display="flex" alignItems="center">
            <EmojiEvents fontSize="large" color="primary" />
            <Box ml={2}>
              <Typography variant="subtitle1" color="textPrimary">
                <Person fontSize="small" color="action" /> {winner.name}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                <Email fontSize="small" color="action" /> {winner.email}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                <Phone fontSize="small" color="action" /> {winner.phone}
              </Typography>
              {/* <Typography variant="body2" color="textSecondary">
                Enviar email?
                <Switch
                  checked={sendEmail}
                  onChange={handleChange}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </Typography> */}
            </Box>
          </Box>
          <Box p={6}>
            <Button onClick={handleCloseRaffle} variant="contained" fullWidth>Cerrar rifa</Button>
          </Box>
        </Paper>
    </Box>
  );
};

export default WinnerSearchResults;
