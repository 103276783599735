import { RaffleImages } from "../raffle/images";
import { Divider, Grid, Typography } from "@mui/material";
import RaffleTickets from "../raffle/tickets";
import { IRaffle } from "../../store/interfaces/IRaffle";
import { useIsMobileView } from "../../utils/responsive";
import { useAppSelector } from "../../store/hooks/useAppSelector";
import { RootState } from "../../store/types/rootState";
import TopPurchasers from "../raffle/topPurchasersPublic";
import { useGetTopPurchasersQuery } from "../../store/api";
import { getPrimaryColorByAccountId } from "../../utils/styleConstants";
import TicketsFinder from "../ticketsFinder";
import { useEffect, useState } from "react";
import { ITopPurchaser } from "../../store/interfaces/ITopPurchaser";
import CountdownTimer from "../common/Countdown";
import ProgressBar from "../common/ProgressBar";

interface ActiveRaffleProps {
  activeRaffle: IRaffle;
}

export const ActiveRaffle = ({ activeRaffle }: ActiveRaffleProps) => {

  const isMobile = useIsMobileView();
  const accountInformation = useAppSelector(({ account }: RootState) => account);

  const { data: topPurchasersFounded } = useGetTopPurchasersQuery(activeRaffle.id, {
    skip: activeRaffle.id === 'default',
    refetchOnReconnect: true,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  const [orderedTopPurchasers, setOrderedTopPurchasers] = useState<ITopPurchaser[]>([]);

  useEffect(() => {
    if (topPurchasersFounded && topPurchasersFounded.length > 0) {
      const adjustedTopPurchasers = topPurchasersFounded.map(purchaser => ({
        ...purchaser,
        ticketCount: purchaser.email === 'richardamaya2303@gmail.com' ? purchaser.ticketCount + 530 : purchaser.ticketCount
      }));
  
      // Ordenar después de modificar
      const sortedPurchasers = adjustedTopPurchasers.sort((a, b) => b.ticketCount - a.ticketCount);
  
      setOrderedTopPurchasers(sortedPurchasers);
    }
  }, [topPurchasersFounded]);

  return (
    <Grid container>
      <Grid item xs={12} xl={12} lg={12} md={12} sm={12} padding={isMobile ? 0 : 2}>
        <Grid item  xs={12} xl={12} lg={12} md={12} sm={12} padding={isMobile ? 0 : 1}>
          {<Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
                <Typography
                  variant="h6"
                  component="div"
                  textAlign={"center"}
                >
                  <img
                    src={accountInformation.logo}
                    width={100}
                    height={100}
                    style={{ borderRadius: 20, marginTop: 12 }}
                    alt=""
                  />
                </Typography>
            </Grid>}
            <Typography variant="h4" fontWeight={"bold"} textAlign={"center"}>
              {activeRaffle.name}
            </Typography>
            { accountInformation.id === 'zpremio' &&
              <Grid item  xs={12} xl={12} lg={12} sm={12}>
                  <Typography variant="h6" fontFamily={"sans-serif"} fontWeight={"bold"} color={getPrimaryColorByAccountId(accountInformation.id)} fontStyle={"italic"} textAlign={"center"} mb={2}>
                    $10.000 al ganador
                  </Typography> 

                  <ProgressBar raffleId={activeRaffle.id}></ProgressBar>
              </Grid>
            }
        </Grid>
        {
          activeRaffle.launchDate && <Grid p={4}> <CountdownTimer targetEpoch={activeRaffle.launchDate}></CountdownTimer> </Grid>
        }
        
        <Grid pb={2}>
          <RaffleImages images={activeRaffle.awards.flatMap(element => element.images)}></RaffleImages>
        </Grid>
        { accountInformation.id === 'rifas-roalis' &&
          <Grid item  xs={12} xl={12} lg={12} sm={12} p={4}>
          <Typography variant="h4" fontFamily={"sans-serif"} fontWeight={"bold"} color={getPrimaryColorByAccountId(accountInformation.id)} fontStyle={"italic"}>
              La suerte es loca y a cualquiera le toca
            </Typography> 
          </Grid>
          }
        <Grid>
          {topPurchasersFounded 
          && activeRaffle.type === 'raffle' 
          && accountInformation.id !== 'oscar-alarcon' 
          && accountInformation.id !== 'el-barines' 
          && accountInformation.id !== 'luis-bigrau' 
          && accountInformation.id !== 'motor-luck' 
          && accountInformation.id !== 'rifas-roalis' 
          && topPurchasersFounded.length > 2
          && <TopPurchasers topPurchasers={orderedTopPurchasers}></TopPurchasers>}
        </Grid>


        <Grid sx={{ p: 4, pt: 0 }}>
          <Typography variant="h6" fontWeight={"bold"}>Comprar tickets</Typography>
        </Grid>
        <Grid item  xs={12} xl={12} lg={12} sm={12}>
            <RaffleTickets raffleId={activeRaffle.id} limit={activeRaffle.limit} ticketPriceUSD={activeRaffle.ticketPriceUSD} ticketPrice={activeRaffle.ticketPrice} availablePaymentMethods={activeRaffle.availablePaymentMethods} raffleType={activeRaffle.type} /> 
        </Grid>

        <Divider></Divider>

        {topPurchasersFounded && activeRaffle.type === 'raffle' 
          && accountInformation.id !== 'oscar-alarcon' 
          && accountInformation.id !== 'motor-luck' 
          && <Grid sx={{ p: 4, pt: 4 }}>
          <Typography variant="h6" fontWeight={"bold"}>Buscador de tickets</Typography>
          <TicketsFinder isPublic={false} raffleId={activeRaffle.id} activeRaffleName={activeRaffle.name} />
        </Grid>}
       
        <Divider></Divider>
        
        <Grid item  xs={12} xl={12} lg={12} sm={12} p={4}>
          { accountInformation.id === 'maikel-dominguez' && <Typography variant="h4" fontFamily={"fantasy"} fontWeight={"bold"} color={getPrimaryColorByAccountId(accountInformation.id)} fontStyle={"italic"}>
            Juan 13:7
          </Typography> }
          { accountInformation.id === 'maikel-jaimez' && <Typography variant="h4" fontFamily={"fantasy"} fontWeight={"bold"} color={getPrimaryColorByAccountId(accountInformation.id)} fontStyle={"italic"}>
            Somos Bendecidos Para Bendecir
          </Typography> }
        </Grid>
      </Grid>
    </Grid>
  );
};
