import { Avatar, Box, Card, CardContent, CardHeader, Chip, Grid, Paper, Stack, Typography } from "@mui/material";
import { CircularProgressRaffle } from "./circularProgress";
import { useIsMobileView } from '../../utils/responsive';
import TopPurchasers from './topPurchasers';
import FaceIcon from '@mui/icons-material/Face';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import TrafficIcon from '@mui/icons-material/Traffic';
import { RaffleActions } from "./actions";
import { useGetRaffleStatusQuery, useGetTopPurchasersQuery } from "../../store/api";
import { useAppSelector } from "../../store/hooks/useAppSelector";
import { RootState } from "../../store/types/rootState";


const getSymbol = (key: string) => {
  if (key === "progress") {
    return "%"
  } else if (key === "earns") {
    return "$" 
  }
};

const getName = (key: string) => {
  if (key === "progress") {
    return "Progreso"
  } else if (key === "earns") {
    return "Ganancias" 
  } else if (key === "earnsUSD") {
    return "Ganancias en dolares" 
  }  else if (key === "totalTicketsSold") {
    return "Total tickets vendidos" 
  }  else if (key === "totalPurchasers") {
    return "Total compradores" 
  }
};


const getIcon = (key: string, isMobileView: boolean) => {
  const commonAvatarProps = {
    sx: { width: isMobileView ? 40 : 60, height: isMobileView ? 40 : 60 },
    fontSize: isMobileView ? 'medium' : 'large',
  };

  switch (key) {
    case "progress":
      return (
        <Avatar sx={{ ...commonAvatarProps, backgroundColor: "#e6e6ff" }}>
          <TrafficIcon sx={{ color: 'blue' }} />
        </Avatar>
      );
    case "totalPurchasers":
      return (
        <Avatar sx={{ ...commonAvatarProps, backgroundColor: "#ffe6e6" }}>
          <FaceIcon sx={{ color: 'red' }} />
        </Avatar>
      );
    case "totalTicketsSold":
      return (
        <Avatar sx={{ ...commonAvatarProps, backgroundColor: "#e6ffee" }}>
          <ConfirmationNumberIcon sx={{ color: 'green' }} />
        </Avatar>
      );
    case "earns":
      return (
        <Avatar sx={{ ...commonAvatarProps, backgroundColor: "#f9e6ff" }}>
          <AttachMoneyIcon sx={{ color: 'purple' }} />
        </Avatar>
      );
    case "earnsUSD":
      return (
        <Avatar sx={{ ...commonAvatarProps, backgroundColor: "green" }}>
          <AttachMoneyIcon sx={{ color: 'white' }} />
        </Avatar>
      );
    default:
      return null;
  }
};


export const RaffleStatus = () => {

  const selectedRaffle = useAppSelector(
    ({ selectedRaffle }: RootState) => selectedRaffle,
  );
  

  const { data: raffleStatusFounded, error: errorGetRaffleStatus } = useGetRaffleStatusQuery(selectedRaffle.id, {
    skip: selectedRaffle.id === 'default',
    refetchOnReconnect: true,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });


  const { data: topPurchasersFounded } = useGetTopPurchasersQuery(selectedRaffle.id, {
    skip: selectedRaffle.id === 'default',
    refetchOnReconnect: true,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });


  const isMobileView = useIsMobileView();

  return (
    <Box>
      <Stack
        spacing={2}
        display={"flex"}
        justifyContent={"space-between"}
        direction={isMobileView ? "column" : "row"}
      >
        {raffleStatusFounded &&
          Object.entries(raffleStatusFounded).map(([key, value]) => (
            <Paper
              key={key}
              sx={{ width: isMobileView ? "80%" : "100%", padding: 4 }}
            >
              <Grid container>
                <Grid item xs={3} md={3}>
                {getIcon(key, isMobileView)}
                </Grid>
                <Grid item xs={12} md={12} sm={12}>
                  <Typography variant="subtitle1">{getName(key)}</Typography>

                  <Typography variant="h5" color="primary">
                    <strong>
                      {key === 'earnsUSD' && selectedRaffle?.id === 'a123f062-809b-4a51-a723-d9076fffa3a0' ? value / 2 : value} 
                      {getSymbol(key)}
                    </strong>
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          ))}
      </Stack>
      {!errorGetRaffleStatus && selectedRaffle && selectedRaffle.id != 'default' ? (
        <Grid container>
          <Grid marginTop={2} item xs={12} sm={12}>
            <Card>
              <CardHeader
                title={
                  <>
                    <Typography variant="h6">
                      {selectedRaffle.name}
                      <Chip sx={{ marginLeft: 2 }} label={selectedRaffle.status === 'ACTIVE' ? 'Activa' : 'Inactiva'}></Chip>
                    </Typography>
                  </>
                }
                subheader={`${selectedRaffle.limit} tickets de $${selectedRaffle.ticketPriceUSD}`}
              />
              <CardContent
                sx={{
                  padding: 3,
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      mb={2}
                    >
                      {
                        selectedRaffle?.id == 'a4d08e48-ae02-4363-8c52-9dea592cf347' ? <CircularProgressRaffle
                        value={
                          100
                        }
                        size={160}
                      />  : <CircularProgressRaffle
                      value={
                        raffleStatusFounded ? raffleStatusFounded.progress : 0
                      }
                      size={160}
                    />
                      }
                      
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TopPurchasers topPurchasers={topPurchasersFounded ?? []} />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <RaffleActions isActiveRaffle={selectedRaffle.status === 'ACTIVE'} raffleId={selectedRaffle.id} />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      ) : selectedRaffle.id == 'default' && (
        <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        paddingTop={2}
        paddingBottom={2}
      >
        <Grid container>
          <Grid marginTop={2} item xs={12} sm={12}>
            <Card>
            <CardContent
                sx={{
                  padding: 3,
                }}
              >
                <Grid container spacing={2}>
                  Seleccione una rifa en el selector para continuar
                </Grid>
              </CardContent>
            </Card>
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default RaffleStatus;
