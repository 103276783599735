import React, { useEffect, useState } from "react";
import { Box, Typography, Paper } from "@mui/material";
import { motion } from "framer-motion";
import { getPrimaryColorByAccountId } from "../../utils/styleConstants";
import { useAppSelector } from "../../store/hooks/useAppSelector";
import { RootState } from "../../store/types/rootState";

interface CountdownTimer {
    targetEpoch: number;
}

const CountdownTimer = ({ targetEpoch }: CountdownTimer) => {

  const accountInformation = useAppSelector(({ account }: RootState) => account);
  const calculateTimeLeft = () => {
    const difference = targetEpoch - Math.floor(Date.now() / 1000);
    return {
      days: Math.floor(difference / (24 * 3600)),
      hours: Math.floor((difference % (24 * 3600)) / 3600),
      minutes: Math.floor((difference % 3600) / 60),
      seconds: difference % 60,
    };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [targetEpoch]);



  return (
    <Paper
      elevation={4}
      sx={{
        p: 1,
        textAlign: "center",
        borderRadius: 3,
        background: `linear-gradient(135deg, ${accountInformation.primaryColor}, ${accountInformation.secondaryColor})`,
        color: "white",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textShadow: '2px 0 #000, -2px 0 #000, 0 2px #000, 0 -2px #000, 1px 1px #000, -1px -1px #000, 1px -1px #000, -1px 1px #000'
      }}
    >
      <Typography variant="h6" fontWeight="bold" gutterBottom sx={{
        
      }}>
        Tiempo restante
      </Typography>
      <motion.div
        animate={{ scale: [1, 1.1, 1] }}
        transition={{ duration: 1, repeat: Infinity, ease: "easeInOut" }}
      >
        <Typography variant="h4" fontWeight="bold">
          {timeLeft.days}d {timeLeft.hours}h {timeLeft.minutes}m {timeLeft.seconds}s
        </Typography>
      </motion.div>
    </Paper>
  );
};

export default CountdownTimer;
