import * as React from 'react';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { PAGES } from '../../constants/pages';
import { useNavigate, useLocation } from 'react-router-dom'; 
import { useAppSelector } from '../../store/hooks/useAppSelector';
import { RootState } from '../../store/types/rootState';
import { useIsMobileView } from '../../utils/responsive';
import { useSelector } from 'react-redux';

const styles = {
  root: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
    zIndex: 2,
  },
  iconStyle: {
    color: 'white',
  },
  selectedIconStyle: {
    color: 'lightblue !important',
  },
};

export default function MobileBottomNavigation() {
  const [value, setValue] = React.useState(0);

  const isLoggedIn = useAppSelector(
    ({ authentication }: RootState) => authentication.isLoggedIn,
  );

  const profile = useSelector(({ user }: RootState) => user.profile);

  const isMobile = useIsMobileView();
  const navigate = useNavigate();
  
  
  const location = useLocation();

  
  React.useEffect(() => {
    const activePageIndex = PAGES.findIndex(page => page.url === location.pathname);
    if (activePageIndex !== -1) {
      setValue(activePageIndex);
    }
  }, [location.pathname]);

  return isLoggedIn && isMobile ? (
    <Box sx={styles.root}>
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        sx={{
          backgroundColor: '#000',
        }}
      >
        {PAGES.filter(page => page.availableProfiles.includes(profile)).map((page, index) => (
          <BottomNavigationAction
            key={page.name}
            icon={page.icon}
            sx={value === index ? styles.selectedIconStyle : styles.iconStyle}
            onClick={() => navigate(page.url)}
          />
        ))}
      </BottomNavigation>
    </Box>
  ) : <></>;
}
