interface IPurchaseOrderStatus {
  id: string;
  title: string;
  description?: string;
  status: "success" | "error" | "info";
}

export const PURCHASE_ORDER_STATUS: IPurchaseOrderStatus[]  = [
    {
      id: 'INITIALIZED',
      title: "Iniciado",
      description: "El cliente seleccionó los tickets e inicio la compra en la plataforma de pago.",
      status: "info"
    },
    {
      id: 'PAYMENT_FAILED',
      title: "Pago fallido",
      description: "Hubo un error al realizar el pago de los tickets.",
      status: "error"
    },
    {
      id: 'SENDING_MAIL_ERROR',
      title: "Error email",
      description: "Hubo un error al realizar enviar el email.",
      status: "error"
    },
    {
      id: 'PURCHASE_ORDER_REJECTED',
      title: "Orden rechazada",
      description: "Se rechazó la orden de compra.",
      status: "error"
    },
    {
      id: 'PAYMENT_SUCCESSFUL',
      title: "Pago exitoso",
      description: "El proceso de pago en la plataforma de pago fue exitoso.",
      status: "success"
    },
    {
      id: 'CASH_PAYMENT_BAD_CREDENTIALS',
      title: "Error al validar credenciales",
      description: "Al momento de generar los tickets de la rifa el usuario ingresó una clave errónea.",
      status: "success"
    },
    
    {
      id: 'PROCESS_FINISHED',
      title: "Proceso finalizado",
      description: "El proceso completo de compra ha sido finzalizado, los tickets han sido enviados por correo al email indicado en la compra.",
      status: "success",
    },
    {
      id: 'PROCESS_INITIALIZED',
      title: "Proceso iniciado",
      description: "Los tickets se están generando, recuerde que el proceso puede demorar de 1 a 5 minutos.",
      status: "info"
    },
    {
      id: 'TRANSFER_PAYMENT_CREATED',
      title: "Requiere validación",
      description: "Se debe revisar el capture para generar los tickets.",
      status: "info"
    },

    
];