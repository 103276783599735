import { Box, Typography } from "@mui/material";
import { useIsMobileView } from "../../utils/responsive";
import { SoldTicketsDashboard } from "../soldTickets/soldTickets";
import { useAppSelector } from "../../store/hooks/useAppSelector";
import { RootState } from "../../store/types/rootState";


export const SoldTicketsPage = () => {
  

  const selectedRaffle = useAppSelector(
    ({ selectedRaffle }: RootState) => selectedRaffle,
  );


  const isMobileView = useIsMobileView();

  return (
    <Box paddingX={isMobileView ? 2 : 10} marginTop={2}>
      <Typography variant="h6" fontFamily={"Georgia"} paddingBottom={2} >
        Números vendidos
      </Typography>

    <Box m={10}>


      {selectedRaffle.id != 'default' ? <SoldTicketsDashboard raffleId={selectedRaffle.id} limit={selectedRaffle.limit} /> : null}
    </Box>

    </Box>
  );
};
