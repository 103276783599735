import { Avatar, Box, Card, CardContent, CardHeader, Chip, Grid, Paper, Stack, Typography } from "@mui/material";
import { useIsMobileView } from '../../utils/responsive';
import FaceIcon from '@mui/icons-material/Face';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import TrafficIcon from '@mui/icons-material/Traffic';
import { useGetRaffleStatusPublicQuery, useGetRaffleStatusQuery, useGetTopPurchasersQuery } from "../../store/api";
import { useAppSelector } from "../../store/hooks/useAppSelector";
import { RootState } from "../../store/types/rootState";
import { CircularProgressRaffle } from "../raffle/circularProgress";


interface ProgressBarProps {
    raffleId: string;
}

export const ProgressBar = ({ raffleId }: ProgressBarProps) => {

    const { data: raffleStatusFounded, error: errorGetRaffleStatus } = useGetRaffleStatusPublicQuery(raffleId, {
        skip: raffleId === 'default',
        refetchOnReconnect: true,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true,
    });



    return (
        <Box>
            <Box display="flex" justifyContent="center" alignItems="center" mb={2}>
                <CircularProgressRaffle
                    value={ raffleStatusFounded ? raffleStatusFounded.progress : 0 } size={100}
                />
            </Box>
        </Box>
    );
};

export default ProgressBar;
