import { useGetPublicSoldTicketsQuery } from "../../store/api";
import { Box, Button, Dialog, Divider, Grid, LinearProgress, Paper, Stack, Typography } from "@mui/material";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { getPrimaryColorByAccountId, getThemeByAccountId, styleConstants } from "../../utils/styleConstants";
import { enqueueSnackbar } from "notistack";
import { Email, EmojiEvents, Person, Phone } from "@mui/icons-material";
import { useAppSelector } from "../../store/hooks/useAppSelector";
import { RootState } from "../../store/types/rootState";

interface SoldTicketsDashboardPublicProps {
    raffleId: string;
    limit: number;
    ticketQuantity: number | null;
    setTicketQuantity:  (ticketQuantity: number) => void;
    setSelectedTickets: Dispatch<SetStateAction<number[] | undefined>>
}
export const SoldTicketsDashboardPublic = ({ raffleId, limit, setTicketQuantity, setSelectedTickets: setSelectedTicketsHandler }: SoldTicketsDashboardPublicProps) => {
    const pageSize = limit === 10000 ? 1000 : limit;
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedTickets, setSelectedTickets] = useState<number[]>([]);
    
    const accountId = useAppSelector(({ account }: RootState) => account.id);
    const { data: getSoldTicketsResponse } = useGetPublicSoldTicketsQuery(raffleId);

    const colorByAccountId = getPrimaryColorByAccountId(accountId) === '#FFFFFF' ? 'blue' : getPrimaryColorByAccountId(accountId);

    useEffect(() => {
        setTicketQuantity(selectedTickets.length)
        setSelectedTicketsHandler(selectedTickets)
    }, [selectedTickets])

    const saveNumber = (number: number, isInSoldTickets: boolean) => {
        if (!isInSoldTickets) {
            setSelectedTickets((prevSelected) =>
                prevSelected.includes(number)
                    ? prevSelected.filter((ticket) => ticket !== number)
                    : [...prevSelected, number]
            );
        }
    };
    const renderTicketNumber = (number: number) => {
        const isInSoldTickets = getSoldTicketsResponse && getSoldTicketsResponse.includes(number) as any;
        const isSelected = selectedTickets.includes(number);
        
        const defaultColor = "#000";  
        
        
        let backgroundColor: string;
        if (isSelected) {
            backgroundColor = "green"; 
        } else if (isInSoldTickets) {
            backgroundColor = colorByAccountId
        } else {
            backgroundColor = 'white'
        }
    
        return (
            <Paper 
                sx={{ 
                    m: 1, 
                    p: 1, 
                    border: `1px solid white`, 
                    width: 60, 
                    textAlign: "center", 
                    backgroundColor: backgroundColor,
                    cursor: 'pointer' 
                }} 
                onClick={() => saveNumber(number, isInSoldTickets)}
            >
                <Typography textAlign="center" variant="body1" color={defaultColor} sx={isInSoldTickets ? { cursor: 'pointer' } : {}}>
                    {number.toString().padStart(limit.toString().length - 1, '0')}
                </Typography>
            </Paper>
        );
    };
    

    const renderTickets = () => {
        const ticketNumbers = [];
        const startIndex = (currentPage - 1) * pageSize;
        const endIndex = Math.min(startIndex + pageSize, limit);
        
        if (getSoldTicketsResponse) {
            for (let i = startIndex; i < endIndex; i++) {
                ticketNumbers.push(
                    <Grid item key={i}>
                        {renderTicketNumber(i)}
                    </Grid>
                );
            }
        }

        return ticketNumbers;
    };

    const totalPages = Math.ceil(limit / pageSize);

    return (
        <Box width={"100%"}>

        
        <Box>
            <Stack direction={"row"} display={"flex"} justifyContent={"space-between"} flexDirection={"column"}>
                    <Box display={"flex"} alignItems={"center"}>
                        <Box sx= {{ width: 10, height: 10, background: 'white' }}></Box>
                        <Typography fontWeight={"bold"} pl={2}>Disponibles</Typography>
                    </Box>
                    <Box display={"flex"} alignItems={"center"}>
                        <Box sx= {{ width: 10, height: 10, background: colorByAccountId }}></Box>
                        <Typography fontWeight={"bold"} pl={2}>No disponibles</Typography>
                    </Box>
                    <Box display={"flex"} alignItems={"center"}>
                        <Box sx= {{ width: 10, height: 10, background: 'green' }}></Box>
                        <Typography fontWeight={"bold"} pl={2}>Seleccionados</Typography>
                    </Box>
                </Stack>
        </Box>
        <Divider sx={{ py: 2 }}></Divider>

            <Grid container pt={2}>
                {renderTickets()}
            </Grid>

            {selectedTickets.length > 0 && (
                <Box mt={2} p={2} width="100%" borderRadius={2} mx={2}>
                    <Typography variant="h6">Tus tickets:</Typography>
                    <Grid container spacing={1}>
                        {selectedTickets.map((ticket) => (
                            <Grid item key={ticket}>
                                <Paper sx={{ p: 1, width: 60, textAlign: "center" }}>
                                    <Typography fontWeight={"bold"}>{ticket.toString().padStart(limit.toString().length - 1, '0')}</Typography>
                                </Paper>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            )}

            {limit === 10000 && (
                <Box mt={2} width="100%" display="flex" justifyContent="space-between">
                    {Array.from({ length: totalPages }, (_, i) => (
                        <Button size="small" key={i + 1} onClick={() => setCurrentPage(i + 1)} sx={{ color: currentPage === i + 1 ? "secondary" : "gray" }} variant="text">
                            {i + 1}
                        </Button>
                    ))}
                </Box>
            )}
        </Box>
    );
};
