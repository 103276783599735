import {  API_URL } from "../../constants/api";
import { BaseQueryFn, FetchArgs, FetchBaseQueryError, createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ACCOUNTS_URL, AUTH_URL, PAYMENTS_URL, PURCHASE_ORDERS_URL, RAFFLES_URL, TICKETS_URL } from "../../constants/urls";
import { ICreationRaffleDTO, ICreationRaffleImageDTO } from "../interfaces/ICreationRaffle";
import { IRaffle } from "../interfaces/IRaffle";
import { IRaffleStatus } from "../interfaces/IRaffleStatus";
import { ITopPurchaser } from "../interfaces/ITopPurchaser";
import { IWinner } from "../interfaces/IWinner";
import { IGetWinnerConf } from "../interfaces/IGetWinnerConf";
import { ICloseRaffleConf } from "../interfaces/ICloseRaffle";
import { IPaymentCommitTransactionConf, IPaymentInitTransactionConf } from "../interfaces/IPaymentInitTransaction";
import { RootState } from "../types/rootState";
import { IAuthenticationRequest } from "../interfaces/IAuth";
import { IUser } from "../interfaces/IUser";
import { IPaymentCommitResponse } from "../interfaces/IPaymentCommitResponse";
import { IGenerateTicketsRequestConf, IGenerateTicketsResponse, ISendEmailByPurchaserRequest } from "../interfaces/ITickets";
import { IPaymentInitTransactionResponse } from "../interfaces/IPaymentInitTransactionResponse";
import { IPurchaseOrder, IRejectPurchaseOrderRequest } from "../interfaces/IPurchaseOrder";
import { IGetTicketsByEmailConf } from "../interfaces/IGetTicketsByEmailConf";
import { logoutAction } from "../slices/auth";
import { saveUserAction } from "../slices/user";
import { enqueueSnackbar } from "notistack";
import { IBlockNumberRequest } from "../interfaces/IBlockNumberRequest";


const baseQuery = fetchBaseQuery({
  baseUrl: API_URL,
  prepareHeaders: (headers, { getState, endpoint }) => {
    const auth = (getState() as RootState).authentication
    if (auth.isLoggedIn) {
        headers.set('Authorization', `Bearer ${auth.token}`)
    }
    return headers
},
})


const baseQueryWithReauth: BaseQueryFn<
string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions)
  if (result.error && result.error.status === 401) {
    api.dispatch(
      logoutAction({
        isLoggedIn: false,
        token: "",
      })
    );
    api.dispatch(saveUserAction(null));
    enqueueSnackbar(`Expiró su token de sesión, por favor logueese nuevamente!`, {
      variant: "error",
      anchorOrigin: {
        horizontal: "left",
        vertical: "top"
      },
    })
    setTimeout(() => {
      window.location.href = "/login";
    }, 2000);
  }
  return result
}

const api = createApi({
  baseQuery: baseQueryWithReauth,
  
  endpoints: (builder) => ({
    auth: builder.mutation<IUser, IAuthenticationRequest>({
      query: (payload) => ({
        url: `/${AUTH_URL}`,
        method: 'POST',
        body: payload,
        credentials: "include",
      }),
    }),
    createRaffle: builder.mutation<ICreationRaffleDTO, ICreationRaffleDTO>({
      query: (payload: ICreationRaffleDTO) => ({
        url: `/${RAFFLES_URL}`,
        method: 'POST',
        body: payload,
        credentials: "include",
      }),
    }),
    closeRaffle: builder.mutation<boolean, ICloseRaffleConf>({
      query: (payload: ICloseRaffleConf) => ({
        url: `/${RAFFLES_URL}/${payload.raffleId}/close`,
        method: 'POST',
        body: payload.conf,
        credentials: "include",
      }),
    }),
    paymentInitTransaction: builder.mutation<IPaymentInitTransactionResponse, IPaymentInitTransactionConf>({
      query: (payload: IPaymentInitTransactionConf) => ({
        url: `/${PAYMENTS_URL}/${payload.raffleId}/initTransaction`,
        method: 'POST',
        body: payload.conf,
        credentials: "include",
      }),
    }),
    paymentCommitTransaction: builder.mutation<IPaymentCommitResponse, IPaymentCommitTransactionConf>({
      query: (payload: IPaymentCommitTransactionConf) => ({
        url: `/${PAYMENTS_URL}/${payload.raffleId}/commit`,
        method: 'PUT',
        body: payload.conf,
        credentials: "include",
      }),
    }),
    rejectPurchaseOrder: builder.mutation<boolean, IRejectPurchaseOrderRequest>({
      query: (payload: IRejectPurchaseOrderRequest) => ({
        url: `/${PURCHASE_ORDERS_URL}/${payload.raffleId}/reject/${payload.purchaseOrderId}`,
        method: 'POST',
        credentials: "include",
      }),
    }),
    generateTickets: builder.mutation<IGenerateTicketsResponse, IGenerateTicketsRequestConf>({
      query: (payload: IGenerateTicketsRequestConf) => ({
        url: `/${TICKETS_URL}/${payload.raffleId}/generate`,
        method: 'POST',
        body: payload.config,
        credentials: "include",
      }),
    }),
    sendEmailPurchaser: builder.mutation<IGenerateTicketsResponse, ISendEmailByPurchaserRequest>({
      query: (payload: ISendEmailByPurchaserRequest) => ({
        url: `/${RAFFLES_URL}/${payload.raffleId}/tickets/sendEmail`,
        method: 'POST',
        body: payload,
        credentials: "include",
      }),
    }),
    createRaffleImage: builder.mutation<boolean, ICreationRaffleImageDTO>({
      query: (dto) => {
        const formData = new FormData();
        formData.append('accountId', dto.accountId);
        formData.append('imageName', dto.imageName);
        formData.append('image', dto.imageFile);
    
        return {
          url: `/${RAFFLES_URL}/${dto.raffleId}/images`,
          method: 'PUT',
          body: formData,
          credentials: 'include',
        };
      },
    }),
    createVoucherImage: builder.mutation<boolean, ICreationRaffleImageDTO>({
      query: (dto) => {
        const formData = new FormData();
        formData.append('accountId', dto.accountId);
        formData.append('imageName', dto.imageName);
        formData.append('image', dto.imageFile);
    
        return {
          url: `/${RAFFLES_URL}/${dto.raffleId}/vouchers`,
          method: 'PUT',
          body: formData,
          credentials: 'include',
        };
      },
    }),
    getActiveRaffle: builder.query<IRaffle, string>({
      query: (accountId) => ({
        url: `/${RAFFLES_URL}/active/${accountId}`,
        method: 'GET',
        credentials: "include",
      }),
    }),
    activeRaffle: builder.mutation<boolean, string>({
      query: (raffleId) => ({
        url: `/${RAFFLES_URL}/${raffleId}/active`,
        method: 'POST',
        credentials: "include",
      }),
    }),
    blockNumber: builder.mutation<boolean, IBlockNumberRequest>({
      query: (blockNumberReques) => ({
        url: `/${RAFFLES_URL}/${blockNumberReques.raffleId}/block/${blockNumberReques.newNumber}`,
        method: 'POST',
        credentials: "include",
      }),
    }),
    unblockNumber: builder.mutation<boolean, IBlockNumberRequest>({
      query: (blockNumberReques) => ({
        url: `/${RAFFLES_URL}/${blockNumberReques.raffleId}/unblock/${blockNumberReques.newNumber}`,
        method: 'POST',
        credentials: "include",
      }),
    }),
    getBlockedNumbersByRaffleId: builder.query<number[], string>({
      query: (raffleId) => ({
        url: `/${RAFFLES_URL}/${raffleId}/blocked`,
        method: 'GET',
        credentials: "include",
      }),
    }),
    getRaffles: builder.query<IRaffle[], undefined>({
      query: () => ({
        url: `/${RAFFLES_URL}`,
        method: 'GET',
        credentials: "include",
      }),
    }),
    getRaffleWinner: builder.query<IWinner, IGetWinnerConf>({
      query: (conf) => ({
        url: `/${RAFFLES_URL}/${conf.raffleId}/winners/${conf.ticket}`,
        method: 'GET',
        credentials: "include",
      }),
    }),
    getTopPurchasers: builder.query<ITopPurchaser[], string>({
      query: (raffleId) => ({
        url: `/top/${raffleId}`,
        method: 'GET',
        credentials: "include",
      }),
    }),
    getPurchasers: builder.query<ITopPurchaser[], string>({
      query: (raffleId) => ({
        url: `/${RAFFLES_URL}/${raffleId}/purchasers`,
        method: 'GET',
        credentials: "include",
      }),
    }),
    getSoldTickets: builder.query<number[], string>({
      query: (raffleId) => ({
        url: `/${RAFFLES_URL}/${raffleId}/soldTickets`,
        method: 'GET',
        credentials: "include",
      }),
    }),
    getPublicSoldTickets: builder.query<number[], string>({
      query: (raffleId) => ({
        url: `/${RAFFLES_URL}/soldTickets/${raffleId}`,
        method: 'GET',
        credentials: "include",
      }),
    }),
    getTicketsByPurchaserEmail: builder.query<number[], IGetTicketsByEmailConf>({
      query: (conf) => ({
        url: `/${RAFFLES_URL}/${conf.raffleId}/tickets/${conf.email}`,
        method: 'GET',
        credentials: "include",
      }),
    }),
    getRaffleStatus: builder.query<IRaffleStatus, string>({
      query: (raffleId) => ({
        url: `/${RAFFLES_URL}/${raffleId}/status`,
        method: 'GET',
        credentials: "include",
      }),
    }),
    getRaffleStatusPublic: builder.query<IRaffleStatus, string>({
      query: (raffleId) => ({
        url: `/${RAFFLES_URL}/status/${raffleId}`,
        method: 'GET',
        credentials: "include",
      }),
    }),
    getAccount: builder.query({
      query: () => ({
        url: `/${ACCOUNTS_URL}`,
        method: 'GET',
        credentials: "include",
      }),
    }),
    getPurchaseOrders: builder.query<IPurchaseOrder[], string>({
      query: (raffleId) => ({
        url: `/${PURCHASE_ORDERS_URL}/${raffleId}`,
        method: 'GET',
        credentials: "include",
      }),
    }),
  })
})

export const { 
  useAuthMutation,
  useCreateRaffleMutation,
  useGetActiveRaffleQuery,
  useGetAccountQuery,
  useCreateRaffleImageMutation,
  useGetRaffleStatusQuery,
  useGetRaffleStatusPublicQuery,
  useGetTopPurchasersQuery,
  useGetRafflesQuery,
  useGetRaffleWinnerQuery,
  useCloseRaffleMutation,
  usePaymentInitTransactionMutation,
  usePaymentCommitTransactionMutation,
  useGenerateTicketsMutation,
  useGetPurchaseOrdersQuery,
  useGetPurchasersQuery,
  useGetTicketsByPurchaserEmailQuery,
  useLazyGetTicketsByPurchaserEmailQuery,
  useCreateVoucherImageMutation,
  useGetSoldTicketsQuery,
  useGetPublicSoldTicketsQuery,
  useRejectPurchaseOrderMutation,
  useLazyGetRaffleWinnerQuery,
  useSendEmailPurchaserMutation,
  useActiveRaffleMutation,
  useGetBlockedNumbersByRaffleIdQuery,
  useBlockNumberMutation,
  useUnblockNumberMutation,
 } = api;

export default api;
