import { combineReducers } from "@reduxjs/toolkit";
import api from "../api";

import userSlice from "../slices/user";
import raffleSlice from "../slices/raffle";
import selectedRaffleSlice from "../slices/selectedRaffle";
import accountSlice from "../slices/account";
import authSlice from "../slices/auth";


const rootReducers = combineReducers({
  user: userSlice,
  authentication: authSlice,
  account: accountSlice,
  raffle: raffleSlice,
  selectedRaffle: selectedRaffleSlice,
  [api.reducerPath]: api.reducer,
});

export default rootReducers;
