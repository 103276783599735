import {
    Paper,
    Typography,
    List,
    ListItem,
    ListItemText,
    Box,
    Stack,
    Chip,
    Button,
    Grid,
} from '@mui/material';
import { BANK_ACCOUNTS, BankAccountItem } from '../../constants/bankAccounts';
import BinanceLogo from "../../assets/images/svg/binance.png";
import PaypalLogo from "../../assets/images/svg/paypal.png";
import { getPrimaryColorByAccountId, getThemeByAccountId } from '../../utils/styleConstants';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/types/rootState';
import { useAppSelector } from '../../store/hooks/useAppSelector';
import { useGetActiveRaffleQuery } from '../../store/api';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { banks } from '../../constants/banks';



interface BankAccountsSelectorProps {
    ticketSelectedCount: number;
    bankAccounts: BankAccountItem[];
    setSelectedBankAccount: Dispatch<SetStateAction<BankAccountItem | undefined>>;
}


const BankAccountSelector = ({ bankAccounts, ticketSelectedCount, setSelectedBankAccount }: BankAccountsSelectorProps)  => {
    const [selectedBankAccount, setSelectedBankAccountAux] = useState<BankAccountItem>();

    useEffect(() => {
        if (bankAccounts.length > 0) {
            setSelectedBankAccountAux(bankAccounts[0])
            setSelectedBankAccount(bankAccounts[0])
        }
    }, [])

    return (<Box mt={2}>
       <Grid container justifyContent="center" mb={2}>
        {bankAccounts.map((element, index) => (
            <Grid item xs={2} key={`${element.bankId}`+index} margin={2}>
            <Button sx={{ p: 1, opacity: selectedBankAccount?.accountNumber?.concat(selectedBankAccount?.bankId || '') === element.accountNumber?.concat(element.bankId || '') ? '' : '0.4' }} variant="outlined" onClick={() => {
                setSelectedBankAccount(element)
                setSelectedBankAccountAux(element)
                }}>
                {banks.find(bank => bank.id === element.bankId)?.icon}
            </Button>
            </Grid>
        ))}
        </Grid>
        {selectedBankAccount && <BankAccount item={selectedBankAccount} ticketSelectedCount={ticketSelectedCount} />}
    </Box>)
}


interface BankAccountsProps {
    ticketSelectedCount: number; 
    accountId: string;
    setSelectedBankAccount: Dispatch<SetStateAction<BankAccountItem | undefined>>;
}

const BankAccounts = ({ticketSelectedCount, accountId, setSelectedBankAccount}: BankAccountsProps) => {

    
    const { data: activeRaffleFounded, refetch } = useGetActiveRaffleQuery(accountId, {
    skip: accountId === "default" || accountId === "",
    });
    const [bankAccounts, setBankAccounts] = useState<BankAccountItem[]>([]);

    useEffect(() => {
        if (activeRaffleFounded && activeRaffleFounded.bankAccounts) {
            setBankAccounts(activeRaffleFounded.bankAccounts)
        } else {
            const config = BANK_ACCOUNTS.find(bankAccount => bankAccount.accountId === accountId);
            if (config != undefined) {
                setBankAccounts(config.bankAccounts)
            }
        }
    }, [activeRaffleFounded]);
    

    
    if (!bankAccounts) {
        return <></>
    }

    return (
        <>
            <Typography variant='h4' fontWeight={"bold"}  textAlign={"center"}>
                DATOS DE TRANSFERENCIA
            </Typography>
            {renderBankAccounts(accountId, bankAccounts, ticketSelectedCount, setSelectedBankAccount)}
            <br />
        </>
    );
}
const renderBankAccounts = (accountId: string, bankAccounts: BankAccountItem[], ticketSelectedCount: number, setSelectedBankAccount: any) => {
    switch (accountId) {
        case 'rifa-sport':
        case 'rifas-naguara':
            return bankAccounts.length > 0 ? (
                <BankAccountSelector 
                    setSelectedBankAccount={setSelectedBankAccount} 
                    bankAccounts={bankAccounts} 
                    ticketSelectedCount={ticketSelectedCount} 
                />
            ) : null;
        
        default:
            return bankAccounts && bankAccounts.map((item, index) => (
                <BankAccount 
                    item={item} 
                    ticketSelectedCount={ticketSelectedCount} 
                    key={index} 
                />
            ));
    }
};


interface BankAccountProps {
    item: BankAccountItem;
    ticketSelectedCount: number;
}

export function BankAccount({ item, ticketSelectedCount }: BankAccountProps) {
    const paperStyle = {
        padding: '30px',
        borderRadius: '10px',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
        margin: '2px',
        marginBottom: '10px',
    };
    const accountId = useSelector(({ account }: RootState) => account.id);

    return (
        <Paper style={paperStyle} elevation={10}>
            <Typography variant='h5' fontWeight={"bold"}>
                {item.bank}
                {item.bank === 'Binance' && <img
                    style={{
                    height: '20px',
                    marginLeft: '8px',
                    borderRadius: '5px'
                    }}
                    src={BinanceLogo}
                    alt=""
                />}
                {item.bank === 'Paypal' && <img
                    style={{
                    height: '20px',
                    marginLeft: '8px',
                    borderRadius: '5px'
                    }}
                    src={PaypalLogo}
                    alt=""
                />}
             </Typography>
            <List>
                {item.dni && (
                    <ListItem>
                        <ListItemText primary={`${item.dni}`} />
                    </ListItem>
                )}
                {item.name && (
                    <ListItem>
                        <ListItemText  primary={`Nombre: ${item.name}`} />
                    </ListItem>
                )}
                {item.accountType && (
                    <ListItem>
                        <ListItemText  primary={`Tipo de cuenta: ${item.accountType}`} />
                    </ListItem>
                )}
                {item.accountNumber && (
                    <ListItem>
                        <ListItemText  primary={`N° de cuenta: ${item.accountNumber}`} />
                    </ListItem>
                )}
                {item.ticketPrice && (
                    <ListItem>
                        <ListItemText  primary={`Precio del ticket: ${item.ticketPrice}`} />
                    </ListItem>
                )}
                {item.conversion && item.conversion.dolarPrice != 0 && (
                    <ListItem sx={{ 
                        backgroundColor: `${getPrimaryColorByAccountId(accountId)} !important`,
                        borderRadius: 2,
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                    }}>
                        <Typography textAlign={"center"} color={`${getThemeByAccountId(accountId) === 'dark' ? '#000 !important' : '#FFF !important' }`} fontWeight={"bold"}>
                            Conversión: {`1$ = ${item.conversion.dolarPrice} ${item.conversion.dolarPriceCurrency}`}
                        </Typography>
                        { ticketSelectedCount > 0 &&
                            <Typography textAlign={"center"} color={`${getThemeByAccountId(accountId) === 'dark' ? '#000 !important' : '#FFF !important' }`} fontWeight={"bold"}>
                                {`${ticketSelectedCount} x ${item.ticketPrice} = ${(item.ticketPriceAmount * ticketSelectedCount).toFixed(2)} ${item.ticketCurrency}`}
                            </Typography>
                        }
                    </ListItem>
                )}
                 {ticketSelectedCount > 0 && (
                    <ListItem sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                        <Typography variant='h5' fontWeight={"bold"} color={"primary"} textAlign={"center"}>
                            {`Total a transferir`}
                        </Typography>
                        <Typography variant='h5' fontWeight={"bold"} color={"primary"} textAlign={"center"}>
                            {`${(item.ticketPriceAmount * ticketSelectedCount).toFixed(2)} ${item.ticketCurrency}`}
                        </Typography>
                    </ListItem>
                )}
            </List>
        </Paper>
    )
}

export default BankAccounts;
