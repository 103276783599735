import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RaffleStateDefault } from "../states/raffleState";
import { IRaffle } from "../interfaces/IRaffle";

const selectedRaffle = createSlice({
    name: 'raffle',
    initialState: RaffleStateDefault,
    reducers: {
        saveSelectedRaffleAction: (state, { payload }: PayloadAction<IRaffle | undefined>) => {
          return {
            ...state,
            ...payload,
          };
        },
    }
})

export const { saveSelectedRaffleAction } = selectedRaffle.actions;

export default selectedRaffle.reducer;