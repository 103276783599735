
import { Box, Typography } from "@mui/material"
import { Dashboard } from "../dashboard"
import { useIsMobileView } from "../../utils/responsive";
import { useAppSelector } from "../../store/hooks/useAppSelector";
import { RootState } from "../../store/types/rootState";



export const DashboardPage = () => {
    const isMobileView = useIsMobileView();
      const selectedRaffleName = useAppSelector(
          ({ selectedRaffle }: RootState) => selectedRaffle.name,
        );
    return (
        <Box paddingX={isMobileView ? 2 : 10} marginTop={2} paddingBottom={8}>
            <Typography variant="h6" fontFamily={"Georgia"} paddingBottom={2}>
                Dashboard
            </Typography>
            <Typography variant="h4" fontWeight={"bold"}>
                {selectedRaffleName}
            </Typography>
        <Dashboard />
    </Box>
)
}