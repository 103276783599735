import SantanderLogo from '../assets/images/banks/banco-santander.png'
import ScotiabankLogo from '../assets/images/banks/banco-scotiabank.png'
import BancoDeChileLogo from '../assets/images/banks/banco-chile.png'
import BancoEstado from '../assets/images/banks/banco-estado.jpg'
import BancoFalabella from '../assets/images/banks/banco-falabella.svg'
import BinanceLogo from "../assets/images/svg/binance.png";
import ZelleLogo from "../assets/images/banks/zelle.png";
import NequiLogo from "../assets/images/banks/nequi.png";
import YapeLogo from "../assets/images/banks/yape.png";

import PaypalLogo from "../assets/images/svg/paypal.png";


import BancoTenpoLogo from "../assets/images/banks/banco-tenpo.png";
import MercadoPagoLogo from "../assets/images/banks/mercado-pago.jpg";
import PlinLogo from "../assets/images/banks/plin.png";
import BancolombiaLogo from "../assets/images/banks/bancolombia.png";
import BancoDeVenezuelaLogo from "../assets/images/banks/banco-de-venezuela.png";
import BanescoLogo from "../assets/images/banks/banesco.png";


import { IBank } from "../store/interfaces/IBankAccount";

 

export const banks: IBank[] = [
    {
      id: 'santander',
      country: 'CL',
      name: 'Santander',
      icon: <img src={SantanderLogo} style={{ borderRadius: 2 }} height={40} width={40} alt='Logo'></img>
    },
    {
      id: 'bancofalabella',
      country: 'CL',
      name: 'Falabella',
      icon: <img src={BancoFalabella} style={{ borderRadius: 2 }} height={40} width={40} alt='Logo'></img>
    },
    {
      id: 'bancochile',
      country: 'CL',
      name: 'Banco de Chile',
      icon: <img src={BancoDeChileLogo} style={{ borderRadius: 2 }} height={40} width={40} alt='Logo'></img>
    },
    {
      id: 'bancoestado',
      country: 'CL',
      name: 'Banco Estado',
      icon: <img src={BancoEstado} style={{ borderRadius: 2 }} height={40} width={40} alt='Logo'></img>
    },
    {
      id: 'scotiabank',
      country: 'CL',
      name: 'Scotiabank',
      icon: <img src={ScotiabankLogo} style={{ borderRadius: 2 }} height={40} width={40} alt='Logo'></img>
    },
    {
      id: 'tenpo',
      country: 'CL',
      name: 'Tenpo',
      icon: <img src={BancoTenpoLogo} style={{ borderRadius: 2 }} height={40} width={40} alt='Logo'></img>
    },
    {
      id: 'mercadopago',
      country: 'CL',
      name: 'Mercado Pago',
      icon: <img src={MercadoPagoLogo} style={{ borderRadius: 2 }} height={40} width={40} alt='Logo'></img>
    },
    {
      id: 'paypal',
      country: 'CL',
      name: 'Paypal',
      icon: <img src={PaypalLogo} style={{ borderRadius: 2 }} height={40} width={40} alt='Logo'></img>
    },
    {
      id: 'binance',
      country: 'CL',
      name: 'Binance',
      icon: <img src={BinanceLogo} style={{ borderRadius: 2 }} height={40} width={40} alt='Logo'></img>
    },
    {
      id: 'plin',
      country: 'CL',
      name: 'Plin',
      icon: <img src={PlinLogo} style={{ borderRadius: 2 }} height={40} width={40} alt='Logo'></img>
    },
    {
      id: 'bancolombia',
      country: 'CL',
      name: 'Bancolombia',
      icon: <img src={BancolombiaLogo} style={{ borderRadius: 2 }} height={40} width={40} alt='Logo'></img>
    },
    {
      id: 'bancodevenezuela',
      country: 'CL',
      name: 'Banco de Venezuela',
      icon: <img src={BancoDeVenezuelaLogo} style={{ borderRadius: 2 }} height={40} width={40} alt='Logo'></img>
    },
    {
      id: 'banesco',
      country: 'CL',
      name: 'Banco de Venezuela',
      icon: <img src={BanescoLogo} style={{ borderRadius: 2 }} height={40} width={40} alt='Logo'></img>
    },
    {
      id: 'zelle',
      country: 'CL',
      name: 'Zelle',
      icon: <img src={ZelleLogo} style={{ borderRadius: 2 }} height={40} width={40} alt='Logo'></img>
    },
    {
      id: 'nequi',
      country: 'CL',
      name: 'Nequi',
      icon: <img src={NequiLogo} style={{ borderRadius: 2 }} height={40} width={40} alt='Logo'></img>
    },
    {
      id: 'yape',
      country: 'CL',
      name: 'Yape',
      icon: <img src={YapeLogo} style={{ borderRadius: 2 }} height={40} width={40} alt='Logo'></img>
    },
  ];
  