import { Award, AwardDTO, ICreationRaffle, ICreationRaffleDTO } from "../../interfaces/ICreationRaffle";

const mapAwardToAwardDTO = (award: Award): AwardDTO => {
    const awardDTO: AwardDTO = {
      name: award.name,
      features: award.features,
      type: award.type,
      images: award.images.map((image) => image.name),
      order: award.order,
    };
    return awardDTO;
  };
  
  export const mapICreationRaffleToDTO = (
    raffle: ICreationRaffle
  ): ICreationRaffleDTO => {
    const awardsDTO: AwardDTO[] = raffle.awards.map((award) =>
      mapAwardToAwardDTO(award)
    );

    const raffleDTO: ICreationRaffleDTO = {
      id: raffle.id,
      name: raffle.name,
      limit: raffle.limit,
      ticketPrice: raffle.ticketPrice,
      ticketPriceUSD: raffle.ticketPriceUSD,
      type: raffle.type,
      sorterId: raffle.sorterId,
      status: raffle.status,
      accountId: raffle.accountId,
      awards: awardsDTO,
      bankAccounts: raffle.bankAccounts,
      availablePaymentMethods: raffle.availablePaymentMethods,
      launchDate: raffle.launchDate,
    };
    return raffleDTO;
  };
  