export const styleConstants = {
    HEADER_HEIGHT: 60,
    HEADER_X_PADDING: 0,
    PADDING_X_HOME: 20,
    PADDING_Y_HOME: 10,
    PRIMARY_COLOR: "#FFFFFF",
    SECONDARY_COLOR: "#000000",
    BACKGROUND_COLOR_LAYOUT: "#000",
    FILTER_SIDEBAR_OPEN: '80px',
    FILTER_SIDEBAR_DEFAULT: '40px',
}

export const getPrimaryColorByAccountId = (accountId: string): string => {
  switch (accountId) {
    case "oscar-alarcon":
      return "#FFFF00"
    case "galenstar":
      return "#FFFFFF"
    case "goyo2time":
      return "#eb3326"
    case "agudojhonny":
      return "#f80303"
    case "minas-de-baruta":
      return "#20a2a3"
    case "oscar-alarcon-promo":
      return "#07FE9F"
    case "maikel-dominguez":
    case "yoiber-mota":
      return "#EA6F06"
    case "white-lion":
      return "#e5a939"
    case "maikel-jaimez":
      return "#fff"
    case "blessed-disc-bar-zz":
    case "rifas-oriente":
    case "danielcontrerasrifas":
    case "rifa-sport":
        return "#3FD2EE"
    case "vip-rifas":
    case "motor-luck":
    case "zpremio":
      return "#e5a939";
    case "jjmc":
      return "#000"
    case "rifas-trebol":
      return "#196b3c"
    case "luis-bigrau":
      return "#FFFF00"
    case "w-robles":
      return "#aeb2b7"
    case "rifas-cachorro":
    case "ruedas-extremas":
    case "rifas-roalis":
      return "#d73533"
    case "antoni-contreras":
      return "#1d2eef"
    case "rifas-naguara":
      return "#c72534"
    case "el-barines":
      return "#fd6f49"
    case "lavueltaesdios":
      return "#66161f"
    default: 
      return "#aeb2b7"
  }
}

export const getThemeByAccountId = (accountId: string): "dark" | "light" => {
  switch (accountId) {
    case "oscar-alarcon":
    case "galenstar":
    case "oscar-alarcon-promo":
    case "danielcontrerasrifas":
    case "vip-rifas":
    case "maikel-dominguez":
    case "blessed-disc-bar-zz":
    case "rifa-sport":
    case "maikel-jaimez":
    case "goyo2time":
    case "rifas-naguara":
      return "dark"
    case "jjmc":
    case "agudojhonny":
    case "white-lion":
    case "rifas-roalis":
    case "rifas-trebol":
      return "light"
    default: 
      return "dark"
  }
}

export const buttonAddStyle = {
    mt: 2,
    marginLeft: 1,
    padding: 0,
    border: 0,
    textDecoration: "underline",
  };