import { Chip, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import { IRaffle } from "../../../store/interfaces/IRaffle";
import { useEffect } from "react";


interface RaffleSelectorProps {
    selectedRaffleId: string; 
    handleSelectRaffle: (e: IRaffle) => void;
    rafflesFounded: IRaffle[]
    setSelectedRaffleId?: (e: string) => void;
    setSelectedRaffleLimit?: (e: number) => void;
}

export const RaffleSelector =({selectedRaffleId, handleSelectRaffle , rafflesFounded, setSelectedRaffleId, setSelectedRaffleLimit}: RaffleSelectorProps) => {

    useEffect(() => {
      console.log("rafflesFounded", rafflesFounded)
      if (rafflesFounded && rafflesFounded.length > 0) {
        rafflesFounded.forEach(raffle => {
          console.log()
          if (raffle.status === 'ACTIVE') {
            handleSelectRaffle(raffle)
            if (setSelectedRaffleId != undefined) {
              setSelectedRaffleId(raffle.id);
            }
            if (setSelectedRaffleLimit != undefined) {
              setSelectedRaffleLimit(raffle.limit)
            }
            return
          }
        });
      }
    }, [rafflesFounded])

    return <FormControl
      sx={{
        width: "100%"
      }}
    >
      <InputLabel id="demo-simple-select-label">
        Seleccionar rifa
      </InputLabel>
      <Select
      size="small"
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={selectedRaffleId}
        label="Seleccionar rifa"
      >
        {rafflesFounded.map((raffle) => (
          <MenuItem
            key={raffle.id}
            value={raffle.id}
            onClick={() => {
              handleSelectRaffle(raffle)
              if (setSelectedRaffleId != undefined) {
                setSelectedRaffleId(raffle.id);
              }
              if (setSelectedRaffleLimit != undefined) {
                setSelectedRaffleLimit(raffle.limit)
              }

            }}
          >
            <Typography paddingRight={2}>
              {raffle.name}
              {raffle.status === 'ACTIVE' && <Chip sx={{ marginLeft: 1 }} label="Activa" color="primary" />}
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>;
  }